<button *ngIf="symbol && !override; else detailButton" class="flex-row button button-primary with-image"
  [class]="symbol === 'tez' ? 'tez' : ''" (click)="prepareTransaction()">
  <svg *ngIf="symbol === 'tez'" width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <path id="svg_1"
      d="m16.4439,9.50064c-0.764,0.764 -2.003,0.764 -2.767,0l-2.66,-2.659l0,9.231c0,1.105 -0.895,2 -2,2c-1.104,0 -2,-0.895 -2,-2l0,-9.254l-2.679,2.68c-0.767,0.767 -2.01,0.767 -2.777,0c-0.767,-0.767 -0.767,-2.01 0,-2.776l5.936,-5.936c0.037,-0.044 0.052,-0.099 0.093,-0.14c0.203,-0.203 0.558,-0.344 0.693,-0.439c0.716,-0.307 1.575,-0.176 2.16,0.409c0.101,0.101 0.152,0.227 0.227,0.342l5.774,5.774c0.765,0.764 0.765,2.004 0,2.768z"
      fill="rgb(255, 255, 255)" fill-rule="evenodd" />
  </svg>
  <img *ngIf="symbol !== 'tez'" [src]="'../../../../assets/img/up-arrow.svg'" />
  <div class="tooltip">
    <div class="description">Send {{ symbol }}</div>
  </div>
</button>
<ng-template #detailButton>
  <button class="button button-primary detail" (click)="prepareTransaction()">SEND</button>
</ng-template>