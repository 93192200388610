/* eslint-disable */
// Last updated on: 2024-01-18
// Inform users of the most common errors
// generated by scripts/generate-rpc-errors-file.ts
// source: http://tezos.gitlab.io/api/errors.html

interface RPCError {
  msg: string;
  category: string;
}

export const rpcErrors: Record<string, RPCError> =
{
  "proto.alpha.Badly_formed_constant_expression": {
    "msg": "Found a badly formed constant expression. The 'constant' primitive must always be followed by a string of the hash of the expression it points to.",
    "category": "temporary"
  },
  "proto.alpha.Bounded_history_repr.Smart_rollup_inbox_history.key_bound_to_different_value": {
    "msg": "Smart_rollup_inbox_history: Remember called with a key that is already bound to a different value.",
    "category": "temporary"
  },
  "proto.alpha.Bounded_history_repr.Smart_rollup_level_inbox_history.key_bound_to_different_value": {
    "msg": "Smart_rollup_level_inbox_history: Remember called with a key that is already bound to a different value.",
    "category": "temporary"
  },
  "proto.alpha.Bounded_history_repr.dal_slots_cache.key_bound_to_different_value": {
    "msg": "dal_slots_cache: Remember called with a key that is already bound to a different value.",
    "category": "temporary"
  },
  "proto.alpha.Dal_attestation_for_wrong_round": {
    "msg": "The DAL attestation targets an unexpected round.",
    "category": "temporary"
  },
  "proto.alpha.Dal_data_availibility_attester_not_in_committee": {
    "msg": "The attester is not part of the DAL committee for this level",
    "category": "temporary"
  },
  "proto.alpha.Dal_operation_for_future_level": {
    "msg": "The Dal operation targets a future level",
    "category": "temporary"
  },
  "proto.alpha.Dal_operation_for_old_level": {
    "msg": "The Dal operation targets an old level",
    "category": "temporary"
  },
  "proto.alpha.Dal_slot_repr.add_element_in_slots_skip_list_violates_ordering": {
    "msg": "Attempting to add an element on top of the Dal confirmed slots skip list that violates the ordering.",
    "category": "temporary"
  },
  "proto.alpha.Dal_slot_repr.invalid_proof_deserialization": {
    "msg": "Error occured during dal proof deserialization",
    "category": "temporary"
  },
  "proto.alpha.Dal_slot_repr.invalid_proof_serialization": {
    "msg": "Error occured during dal proof serialization",
    "category": "temporary"
  },
  "proto.alpha.Dissecting_during_final_move": {
    "msg": "Tried to play a dissecting when the final move started",
    "category": "temporary"
  },
  "proto.alpha.Dissection_choice_not_found": {
    "msg": "Dissection choice not found",
    "category": "temporary"
  },
  "proto.alpha.Dissection_unexpected_section_size": {
    "msg": "The distance for a proof should be equal to 1",
    "category": "temporary"
  },
  "proto.alpha.Expected_binary_proof": {
    "msg": "An invalid proof has been submitted",
    "category": "temporary"
  },
  "proto.alpha.Expression_already_registered": {
    "msg": "Attempted to register an expression as global constant that has already been registered.",
    "category": "temporary"
  },
  "proto.alpha.Expression_too_deep": {
    "msg": "Attempted to register an expression that, after fully expanding all referenced global constants, would result in too many levels of nesting.",
    "category": "temporary"
  },
  "proto.alpha.Expression_too_large": {
    "msg": "Encountered an expression that, after expanding all constants, is larger than the expression size limit.",
    "category": "temporary"
  },
  "proto.alpha.Failed_to_get_script": {
    "msg": "Failed to get script for contract when scanning operations for tickets",
    "category": "temporary"
  },
  "proto.alpha.Failed_to_hash_node": {
    "msg": "Failed to hash node for a key in the ticket-balance table",
    "category": "temporary"
  },
  "proto.alpha.Failed_to_load_big_map_value_type": {
    "msg": "Failed to load big-map value type when computing ticket diffs.",
    "category": "temporary"
  },
  "proto.alpha.Merkle_list_invalid_position": {
    "msg": "Merkle_list_invalid_position",
    "category": "temporary"
  },
  "proto.alpha.Negative_ticket_balance": {
    "msg": "Attempted to set a negative ticket balance value",
    "category": "temporary"
  },
  "proto.alpha.Nonexistent_global": {
    "msg": "No registered global was found at the given hash in storage.",
    "category": "temporary"
  },
  "proto.alpha.Proof_start_state_hash_mismatch": {
    "msg": "The start state hash of the proof is invalid",
    "category": "temporary"
  },
  "proto.alpha.Proof_stop_state_hash_failed_to_refute": {
    "msg": "Failed to refute the stop state hash with the proof",
    "category": "temporary"
  },
  "proto.alpha.Proof_stop_state_hash_failed_to_validate": {
    "msg": "Failed to validate the stop state hash with the proof",
    "category": "temporary"
  },
  "proto.alpha.Unsupported_non_empty_overlay": {
    "msg": "Unsupported big-map value with non-empty overlay",
    "category": "temporary"
  },
  "proto.alpha.Unsupported_type_operation": {
    "msg": "Types embedding operations are not supported",
    "category": "temporary"
  },
  "proto.alpha.Used_storage_underflow": {
    "msg": "Attempt to free more bytes than allocated for the tickets balance",
    "category": "temporary"
  },
  "proto.alpha.apply.missing_shell_header": {
    "msg": "During finalisation of a block header in Application mode or Full construction mode, a shell header should be provided so that a cache nonce can be computed.",
    "category": "temporary"
  },
  "proto.alpha.baking.insufficient_attestation_power": {
    "msg": "The attestation power is insufficient to satisfy the consensus threshold.",
    "category": "temporary"
  },
  "proto.alpha.bitfield_invalid_position": {
    "msg": "Bitfields does not accept negative positions",
    "category": "temporary"
  },
  "proto.alpha.block.multiple_revelation": {
    "msg": "A manager operation should not contain more than one revelation",
    "category": "temporary"
  },
  "proto.alpha.block_header.invalid_block_signature": {
    "msg": "A block was not signed with the expected private key.",
    "category": "temporary"
  },
  "proto.alpha.block_header.invalid_commitment": {
    "msg": "The block header has invalid commitment.",
    "category": "temporary"
  },
  "proto.alpha.block_header.invalid_payload_round": {
    "msg": "The given payload round is invalid.",
    "category": "temporary"
  },
  "proto.alpha.block_header.invalid_stamp": {
    "msg": "The block's proof-of-work stamp is insufficient",
    "category": "temporary"
  },
  "proto.alpha.block_header.wrong_timestamp": {
    "msg": "Block timestamp not the expected one.",
    "category": "temporary"
  },
  "proto.alpha.bootstrap.unrevealed_public_key": {
    "msg": "Tried to delegate from an unrevealed public key",
    "category": "temporary"
  },
  "proto.alpha.cannot_retrieve_predecessor_level": {
    "msg": "Cannot retrieve predecessor level.",
    "category": "temporary"
  },
  "proto.alpha.constants.invalid_protocol_constants": {
    "msg": "The provided protocol constants are not coherent.",
    "category": "temporary"
  },
  "proto.alpha.context.failed_to_decode_parameter": {
    "msg": "Unexpected JSON object.",
    "category": "temporary"
  },
  "proto.alpha.context.failed_to_parse_parameter": {
    "msg": "The protocol parameters are not valid JSON.",
    "category": "temporary"
  },
  "proto.alpha.context.storage_error": {
    "msg": "An error that should never happen unless something has been deleted or corrupted in the database.",
    "category": "temporary"
  },
  "proto.alpha.contract.balance_too_low": {
    "msg": "An operation tried to spend more tokens than the contract has",
    "category": "temporary"
  },
  "proto.alpha.contract.cannot_pay_storage_fee": {
    "msg": "The storage fee is higher than the contract balance",
    "category": "temporary"
  },
  "proto.alpha.contract.counter_in_the_future": {
    "msg": "An operation assumed a contract counter in the future",
    "category": "temporary"
  },
  "proto.alpha.contract.counter_in_the_past": {
    "msg": "An operation assumed a contract counter in the past",
    "category": "temporary"
  },
  "proto.alpha.contract.empty_transaction": {
    "msg": "Forbidden to credit 0ꜩ to a contract without code.",
    "category": "temporary"
  },
  "proto.alpha.contract.failure": {
    "msg": "Unexpected contract storage error",
    "category": "temporary"
  },
  "proto.alpha.contract.invalid_contract_notation": {
    "msg": "A malformed contract notation was given to an RPC or in a script.",
    "category": "temporary"
  },
  "proto.alpha.contract.manager.inconsistent_hash": {
    "msg": "A revealed manager public key is inconsistent with the announced hash",
    "category": "temporary"
  },
  "proto.alpha.contract.manager.inconsistent_public_key": {
    "msg": "A provided manager public key is different with the public key stored in the contract",
    "category": "temporary"
  },
  "proto.alpha.contract.manager.unregistered_delegate": {
    "msg": "A contract cannot be delegated to an unregistered delegate",
    "category": "temporary"
  },
  "proto.alpha.contract.missing_manager_contract": {
    "msg": "The manager contract is missing from the storage",
    "category": "temporary"
  },
  "proto.alpha.contract.negative_storage_input": {
    "msg": "The storage amount asked for an operation is null or negative",
    "category": "temporary"
  },
  "proto.alpha.contract.non_empty_transaction_from_source": {
    "msg": "This address cannot initiate non-empty transactions",
    "category": "temporary"
  },
  "proto.alpha.contract.non_existing_contract": {
    "msg": "A contract handle is not present in the context (either it never was or it has been destroyed)",
    "category": "temporary"
  },
  "proto.alpha.contract.previously_revealed_key": {
    "msg": "One tried to reveal twice a manager public key",
    "category": "temporary"
  },
  "proto.alpha.contract.unrevealed_key": {
    "msg": "One tried to apply a manager operation without revealing the manager public key",
    "category": "temporary"
  },
  "proto.alpha.contract_not_originated": {
    "msg": "Non originated contract detected in ticket update.",
    "category": "temporary"
  },
  "proto.alpha.dal_attestation_size_limit_exceeded": {
    "msg": "The attestation for data availability is a too big",
    "category": "temporary"
  },
  "proto.alpha.dal_attestation_unexpected_size": {
    "msg": "The attestation for data availability has a different size",
    "category": "temporary"
  },
  "proto.alpha.dal_cryptobox_error": {
    "msg": "Error occurred while initialising the cryptobox",
    "category": "temporary"
  },
  "proto.alpha.dal_publish_slot_header_duplicate": {
    "msg": "A slot header for this slot was already proposed",
    "category": "temporary"
  },
  "proto.alpha.dal_publish_slot_header_invalid_index": {
    "msg": "Bad index for slot header",
    "category": "temporary"
  },
  "proto.alpha.dal_publish_slot_header_invalid_proof": {
    "msg": "The slot header's commitment proof does not check",
    "category": "temporary"
  },
  "proto.alpha.dal_publish_slot_header_with_low_fees": {
    "msg": "Slot header with too low fees",
    "category": "temporary"
  },
  "proto.alpha.dal_register_invalid_slot": {
    "msg": "Attempt to register a slot which is invalid (the index is out of bounds).",
    "category": "temporary"
  },
  "proto.alpha.dal_slot_index_negative_orabove_hard_limit": {
    "msg": "Slot index above hard limit",
    "category": "temporary"
  },
  "proto.alpha.dal_slot_index_repr.index.invalid_index": {
    "msg": "The given index is out of range of representable slot indices",
    "category": "temporary"
  },
  "proto.alpha.dal_slot_repr.slots_history.dal_proof_error": {
    "msg": "Error occurred during Dal proof production or validation",
    "category": "temporary"
  },
  "proto.alpha.dal_slot_repr.slots_history.unexpected_page_size": {
    "msg": "The size of the given page content doesn't match the expected one.",
    "category": "temporary"
  },
  "proto.alpha.dal_unexpected_attestation_at_root_level": {
    "msg": "DAL attestations are not expected at root level",
    "category": "temporary"
  },
  "proto.alpha.delegate.already_active": {
    "msg": "Useless delegate reactivation",
    "category": "temporary"
  },
  "proto.alpha.delegate.consensus_key.active": {
    "msg": "The delegate consensus key is already used by another delegate",
    "category": "temporary"
  },
  "proto.alpha.delegate.consensus_key.invalid_noop": {
    "msg": "Tried to update the consensus key with the active key",
    "category": "temporary"
  },
  "proto.alpha.delegate.consensus_key.tz4": {
    "msg": "Consensus key cannot be a tz4 (BLS public key).",
    "category": "temporary"
  },
  "proto.alpha.delegate.empty_delegate_account": {
    "msg": "Cannot register a delegate when its implicit account is empty",
    "category": "temporary"
  },
  "proto.alpha.delegate.forbidden_tz4": {
    "msg": "Delegates are forbidden to be tz4 (BLS) accounts.",
    "category": "temporary"
  },
  "proto.alpha.delegate.no_deletion": {
    "msg": "Tried to unregister a delegate",
    "category": "temporary"
  },
  "proto.alpha.delegate.not_registered": {
    "msg": "The provided public key hash is not the address of a registered delegate.",
    "category": "temporary"
  },
  "proto.alpha.delegate.stake_distribution_not_set": {
    "msg": "The stake distribution for the current cycle is not set.",
    "category": "temporary"
  },
  "proto.alpha.delegate.unchanged": {
    "msg": "Contract already delegated to the given delegate",
    "category": "temporary"
  },
  "proto.alpha.delegate_service.balance_rpc_on_non_delegate": {
    "msg": "The account whose balance was requested is not a delegate.",
    "category": "temporary"
  },
  "proto.alpha.destination_repr.invalid_b58check": {
    "msg": "Failed to read a valid destination from a b58check_encoding data",
    "category": "temporary"
  },
  "proto.alpha.durations.non_increasing_rounds": {
    "msg": "The provided rounds are not increasing.",
    "category": "temporary"
  },
  "proto.alpha.failure": {
    "msg": "Exception safely wrapped in an error",
    "category": "temporary"
  },
  "proto.alpha.frozen_bonds.must_be_spent_at_once": {
    "msg": "Frozen bonds must be spent at once.",
    "category": "temporary"
  },
  "proto.alpha.gas_exhausted.block": {
    "msg": "The sum of gas consumed by all the operations in the block exceeds the hard gas limit per block",
    "category": "temporary"
  },
  "proto.alpha.gas_exhausted.operation": {
    "msg": "A script or one of its callee took more time than the operation said it would",
    "category": "temporary"
  },
  "proto.alpha.gas_limit_too_high": {
    "msg": "A transaction tried to exceed the hard limit on gas",
    "category": "temporary"
  },
  "proto.alpha.illformedViewType": {
    "msg": "An entrypoint type is incompatible with TZIP-4 view type.",
    "category": "temporary"
  },
  "proto.alpha.implicit.empty_implicit_contract": {
    "msg": "No manager operations are allowed on an empty implicit contract.",
    "category": "temporary"
  },
  "proto.alpha.implicit.empty_implicit_delegated_contract": {
    "msg": "Emptying an implicit delegated account is not allowed.",
    "category": "temporary"
  },
  "proto.alpha.indexable.index_cannot_be_negative": {
    "msg": "A negative integer cannot be used as an index for a value.",
    "category": "temporary"
  },
  "proto.alpha.internal.smart_rollup_add_zero_messages": {
    "msg": "Message adding functions must be called with a positive number of messages",
    "category": "temporary"
  },
  "proto.alpha.internal.smart_rollup_inbox_proof_error": {
    "msg": "An inbox proof error.",
    "category": "temporary"
  },
  "proto.alpha.internal.smart_rollup_merklized_payload_hashes_proof": {
    "msg": "A merkelized payload hashes proof error.",
    "category": "temporary"
  },
  "proto.alpha.internal_operation_replay": {
    "msg": "An internal operation was emitted twice by a script",
    "category": "temporary"
  },
  "proto.alpha.invalid_arg": {
    "msg": "Negative multiple of periods are not allowed.",
    "category": "temporary"
  },
  "proto.alpha.invalid_binary_format": {
    "msg": "Could not deserialize some piece of data from its binary representation",
    "category": "temporary"
  },
  "proto.alpha.invalid_fitness": {
    "msg": "Fitness representation should be exactly 4 times 4 bytes long.",
    "category": "temporary"
  },
  "proto.alpha.invalid_ticket_transfer": {
    "msg": "Invalid ticket transfer detected in ticket balance update.",
    "category": "temporary"
  },
  "proto.alpha.level_not_in_alpha": {
    "msg": "Level not in Alpha family",
    "category": "temporary"
  },
  "proto.alpha.level_offset_too_high": {
    "msg": "The block's level offset is too high.",
    "category": "temporary"
  },
  "proto.alpha.level_repr.invalid_cycle_eras": {
    "msg": "The cycles eras are not valid: empty list or non-decreasing first levels or first cycles.",
    "category": "temporary"
  },
  "proto.alpha.locked_round_not_less_than_round": {
    "msg": "The round is smaller than or equal to the locked round.",
    "category": "temporary"
  },
  "proto.alpha.main.begin_application.cannot_apply_in_partial_validation": {
    "msg": "Cannot instantiate an application state using the 'Partial_validation' mode.",
    "category": "temporary"
  },
  "proto.alpha.malformed_period": {
    "msg": "Period is negative.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.bad_contract_parameter": {
    "msg": "Either no parameter was supplied to a contract with a non-unit parameter type, a non-unit parameter was passed to an account, or a parameter was supplied of the wrong type",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.bad_dupn_argument": {
    "msg": "DUP expects an argument of at least 1 (passed 0)",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.bad_dupn_stack": {
    "msg": "Stack present when typing DUP n was too short",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.bad_pair_argument": {
    "msg": "PAIR expects an argument of at least 2",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.bad_return": {
    "msg": "Unexpected stack at the end of a lambda or script.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.bad_stack": {
    "msg": "The stack has an unexpected length or contents.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.bad_stack_item": {
    "msg": "The type of a stack item is unexpected (this error is always accompanied by a more precise one).",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.bad_unpair_argument": {
    "msg": "UNPAIR expects an argument of at least 2",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.bad_view_name": {
    "msg": "In a view declaration, the view name must be a string",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.cannot_serialize_failure": {
    "msg": "Argument of FAILWITH was too big to be serialized with the provided gas",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.cannot_serialize_log": {
    "msg": "Execution trace with stacks was to big to be serialized with the provided gas",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.cannot_serialize_storage": {
    "msg": "The returned storage was too big to be serialized with the provided gas",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.comparable_type_expected": {
    "msg": "A non comparable type was used in a place where only comparable types are accepted.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.deprecated_instruction": {
    "msg": "A deprecated instruction usage is disallowed in newly created contracts",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.duplicate_entrypoint": {
    "msg": "Two entrypoints have the same name.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.duplicate_map_keys": {
    "msg": "Map literals cannot contain duplicated keys",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.duplicate_script_field": {
    "msg": "When parsing script, a field was found more than once",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.duplicate_set_values_in_literal": {
    "msg": "Set literals cannot contain duplicate elements, but a duplicate was found while parsing.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.duplicated_view_name": {
    "msg": "The name of view in toplevel should be unique.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.entrypoint_name_too_long": {
    "msg": "An entrypoint name exceeds the maximum length of 31 characters.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.fail_not_in_tail_position": {
    "msg": "There is non trivial garbage code after a FAIL instruction.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.forbidden_instr_in_context": {
    "msg": "An instruction was encountered in a context where it is forbidden.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.forbidden_zero_amount_ticket": {
    "msg": "It is not allowed to use a zero amount ticket in this operation.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.ill_formed_type": {
    "msg": "The toplevel error thrown when trying to parse a type expression (always followed by more precise errors).",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.ill_typed_contract": {
    "msg": "The toplevel error thrown when trying to typecheck a contract code against given input, output and storage types (always followed by more precise errors).",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.ill_typed_data": {
    "msg": "The toplevel error thrown when trying to typecheck a data expression against a given type (always followed by more precise errors).",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.ill_typed_view": {
    "msg": "The return of a view block did not match the expected type",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.inconsistent_memo_sizes": {
    "msg": "Memo sizes of two sapling states or transactions do not match",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.inconsistent_stack_lengths": {
    "msg": "A stack was of an unexpected length (this error is always in the context of a located error).",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.inconsistent_type_sizes": {
    "msg": "Two types were expected to be equal but they have different sizes.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.inconsistent_types": {
    "msg": "This is the basic type clash error, that appears in several places where the equality of two types have to be proven, it is always accompanied with another error that provides more context.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_arity": {
    "msg": "In a script or data expression, a primitive was applied to an unsupported number of arguments.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_big_map": {
    "msg": "A script or data expression references a big_map that does not exist or assumes a wrong type for an existing big_map.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_constant": {
    "msg": "A data expression was invalid for its expected type.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_contract": {
    "msg": "A script or data expression references a contract that does not exist or assumes a wrong type for an existing contract.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_expression_kind": {
    "msg": "In a script or data expression, an expression was of the wrong kind (for instance a string where only a primitive applications can appear).",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_iter_body": {
    "msg": "The body of an ITER instruction must result in the same stack type as before the ITER.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_map_block_fail": {
    "msg": "FAIL cannot be the only instruction in the body. The proper type of the return list cannot be inferred.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_map_body": {
    "msg": "The body of a map block did not match the expected type",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_never_expr": {
    "msg": "In a script or data expression, an expression was provided but a value of type never was expected. No expression can have type never.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_primitive": {
    "msg": "In a script or data expression, a primitive was unknown.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_primitive_name": {
    "msg": "In a script or data expression, a primitive name is unknown or has a wrong case.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_primitive_name_case": {
    "msg": "In a script or data expression, a primitive name is neither uppercase, lowercase or capitalized.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_primitive_namespace": {
    "msg": "In a script or data expression, a primitive was of the wrong namespace.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_seq_arity": {
    "msg": "In a script or data expression, a sequence was used with a number of elements too small.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_syntactic_constant": {
    "msg": "A compile-time constant was invalid for its expected form.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.invalid_tx_rollup_ticket_amount": {
    "msg": "Ticket amount to be deposited in a transaction rollup should be strictly positive and fit in a signed 64-bit integer",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.missing_script_field": {
    "msg": "When parsing script, a field was expected, but not provided",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.no_such_entrypoint": {
    "msg": "An entrypoint was not found when calling a contract.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.non_dupable_type": {
    "msg": "DUP was used on a non-dupable type (e.g. tickets).",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.non_printable_character": {
    "msg": "Michelson strings are only allowed to contain printable characters (either the newline character or characters in the [32, 126] ASCII range).",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.runtime_error": {
    "msg": "Toplevel error for all runtime script errors",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.sc_rollup_disabled": {
    "msg": "Cannot use smart rollup features as they are disabled.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.script_overflow": {
    "msg": "While interpreting a Michelson script, an overflow was detected",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.script_rejected": {
    "msg": "A FAILWITH instruction was reached",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.tx_rollup_bad_deposit_parameter": {
    "msg": "The parameter to the deposit entrypoint of a transaction rollup should be a pair of a ticket and the address of a recipient transaction rollup.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.type_too_large": {
    "msg": "An instruction generated a type larger than the limit.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.typechecking_too_many_recursive_calls": {
    "msg": "Too many recursive calls were needed for typechecking",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.undefined_binop": {
    "msg": "A binary operation is called on operands of types over which it is not defined.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.undefined_unop": {
    "msg": "A unary operation is called on an operand of type over which it is not defined.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unexpected_annotation": {
    "msg": "A node in the syntax tree was improperly annotated",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unexpected_contract": {
    "msg": "When parsing script, a contract type was found in the storage or parameter field.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unexpected_default_entrypoint": {
    "msg": "A node in the syntax tree was improperly annotated. An annotation used to designate an entrypoint cannot be exactly 'default'.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unexpected_forged_value": {
    "msg": "A forged value was encountered but disallowed for that position.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unexpected_implicit_account_parameters_type": {
    "msg": "An implicit account can only accept either a unit or a ticket value as a call parameter.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unexpected_lazy_storage": {
    "msg": "When parsing script, a big_map or sapling_state type was found in a position where it could end up stored inside a big_map, which is forbidden for now.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unexpected_operation": {
    "msg": "When parsing script, an operation type was found in the storage or parameter field.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unexpected_ticket": {
    "msg": "A ticket type has been found",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unexpected_ticket_owner": {
    "msg": "Ticket can only be created by a smart contract",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.ungrouped_annotations": {
    "msg": "Annotations of the same kind must be grouped",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unknown_primitive_name": {
    "msg": "In a script or data expression, a primitive was unknown.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unmatched_branches": {
    "msg": "At the join point at the end of two code branches the stacks have inconsistent lengths or contents.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unordered_map_literal": {
    "msg": "Map keys must be in strictly increasing order",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unordered_set_literal": {
    "msg": "Set values must be in strictly increasing order",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unparsing_stack_overflow": {
    "msg": "Too many recursive calls were needed for unparsing",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.unreachable_entrypoint": {
    "msg": "An entrypoint in the contract is not reachable.",
    "category": "temporary"
  },
  "proto.alpha.michelson_v1.view_name_too_long": {
    "msg": "A view name exceeds the maximum length of 31 characters.",
    "category": "temporary"
  },
  "proto.alpha.negative_level_and_offset_sum": {
    "msg": "Negative sum of level and offset",
    "category": "temporary"
  },
  "proto.alpha.negative_level_offset": {
    "msg": "The specified level offset is negative",
    "category": "temporary"
  },
  "proto.alpha.negative_round": {
    "msg": "Round cannot be built out of negative integers.",
    "category": "temporary"
  },
  "proto.alpha.no_available_snapshots": {
    "msg": "No available snapshots",
    "category": "temporary"
  },
  "proto.alpha.nonce.already_revealed": {
    "msg": "Duplicated revelation for a nonce.",
    "category": "temporary"
  },
  "proto.alpha.nonce.inconsistent": {
    "msg": "The provided nonce is inconsistent with the committed nonce hash.",
    "category": "temporary"
  },
  "proto.alpha.nonce.too_early_revelation": {
    "msg": "Nonce revelation happens before cycle end",
    "category": "temporary"
  },
  "proto.alpha.nonce.too_late_revelation": {
    "msg": "Nonce revelation happens too late",
    "category": "temporary"
  },
  "proto.alpha.operation.arith_pvm_disabled": {
    "msg": "Arith PVM is disabled in this network.",
    "category": "temporary"
  },
  "proto.alpha.operation.ballot_from_unregistered_delegate": {
    "msg": "Cannot cast a ballot for an unregistered delegate.",
    "category": "temporary"
  },
  "proto.alpha.operation.cannot_parse": {
    "msg": "The operation is ill-formed or for another protocol version",
    "category": "temporary"
  },
  "proto.alpha.operation.cannot_stake_with_unfinalizable_unstake_requests_to_another_delegate": {
    "msg": "A contract tries to stake to its delegate while having unstake requests to a previous delegate that cannot be finalized yet. Try again in a later cycle (no more than preserved_cycles + max_slashing_period).",
    "category": "temporary"
  },
  "proto.alpha.operation.contents_list_error": {
    "msg": "An operation contents list has an unexpected shape; it should be either a single operation or a non-empty list of manager operations",
    "category": "temporary"
  },
  "proto.alpha.operation.dal_disabled": {
    "msg": "Data-availability layer will be enabled in a future proposal.",
    "category": "temporary"
  },
  "proto.alpha.operation.double_inclusion_of_consensus_operation": {
    "msg": "double inclusion of consensus operation",
    "category": "temporary"
  },
  "proto.alpha.operation.drain_delegate_key_on_unregistered_delegate": {
    "msg": "Cannot drain an unregistered delegate.",
    "category": "temporary"
  },
  "proto.alpha.operation.error_while_taking_fees": {
    "msg": "There was an error while taking the fees, which should not happen and means that the operation's validation was faulty.",
    "category": "temporary"
  },
  "proto.alpha.operation.faulty_validation_wrong_slot": {
    "msg": "The consensus operation uses an invalid slot. This error should not happen: the operation validation should have failed earlier.",
    "category": "temporary"
  },
  "proto.alpha.operation.invalid_drain.inactive_key": {
    "msg": "Cannot drain with an inactive consensus key.",
    "category": "temporary"
  },
  "proto.alpha.operation.invalid_drain.insufficient_funds_for_burn_or_fees": {
    "msg": "Cannot drain without enough allocation burn and drain fees.",
    "category": "temporary"
  },
  "proto.alpha.operation.invalid_drain.no_consensus_key": {
    "msg": "Cannot drain a delegate without consensus key.",
    "category": "temporary"
  },
  "proto.alpha.operation.invalid_drain.noop": {
    "msg": "Cannot drain a delegate to itself.",
    "category": "temporary"
  },
  "proto.alpha.operation.invalid_signature": {
    "msg": "The operation signature is ill-formed or has been made with the wrong public key",
    "category": "temporary"
  },
  "proto.alpha.operation.manual_staking_forbidden": {
    "msg": "Manual staking operations are forbidden because staking is currently automated.",
    "category": "temporary"
  },
  "proto.alpha.operation.missing_signature": {
    "msg": "The operation is of a kind that must be signed, but the signature is missing",
    "category": "temporary"
  },
  "proto.alpha.operation.proposals_from_unregistered_delegate": {
    "msg": "Cannot submit proposals with an unregistered delegate.",
    "category": "temporary"
  },
  "proto.alpha.operation.riscv_pvm_disabled": {
    "msg": "RISCV PVM is disabled in this network.",
    "category": "temporary"
  },
  "proto.alpha.operation.rollup_invalid_entrypoint": {
    "msg": "Rollups only support transactions to the default entrypoint.",
    "category": "temporary"
  },
  "proto.alpha.operation.rollup_invalid_transaction_amount": {
    "msg": "Because rollups are outside of the delegation mechanism of Tezos, they cannot own Tez, and therefore transactions targeting a rollup must have its amount field set to zero.",
    "category": "temporary"
  },
  "proto.alpha.operation.set_deposits_limit_on_unregistered_delegate": {
    "msg": "Cannot set deposits limit on an unregistered delegate.",
    "category": "temporary"
  },
  "proto.alpha.operation.update_consensus_key_on_unregistered_delegate": {
    "msg": "Cannot update consensus key an unregistered delegate.",
    "category": "temporary"
  },
  "proto.alpha.operation.zk_rollup_deposit_as_external": {
    "msg": "Zk_rollup: attempted a deposit through an external op",
    "category": "temporary"
  },
  "proto.alpha.operation.zk_rollup_disabled": {
    "msg": "ZK rollups will be enabled in a future proposal.",
    "category": "temporary"
  },
  "proto.alpha.operation.zk_rollup_failed_verification": {
    "msg": "Zk_rollup_update: failed verification",
    "category": "temporary"
  },
  "proto.alpha.operation.zk_rollup_inconsistent_state_update": {
    "msg": "Zk_rollup_update: new state is of incorrect size",
    "category": "temporary"
  },
  "proto.alpha.operation.zk_rollup_invalid_circuit": {
    "msg": "Zk_rollup_update: invalid circuit",
    "category": "temporary"
  },
  "proto.alpha.operation.zk_rollup_invalid_deposit_amount": {
    "msg": "Zk_rollup: attempted a deposit with an invalid amount",
    "category": "temporary"
  },
  "proto.alpha.operation.zk_rollup_invalid_deposit_ticket": {
    "msg": "Zk_rollup: attempted a deposit with an invalid ticket",
    "category": "temporary"
  },
  "proto.alpha.operation.zk_rollup_negative_nb_ops": {
    "msg": "The value of [nb_ops] should never be negative.",
    "category": "temporary"
  },
  "proto.alpha.operation.zk_rollup_pending_bound": {
    "msg": "Zk_rollup_update: update with fewer pending ops than allowed",
    "category": "temporary"
  },
  "proto.alpha.operation.zk_rollup_wrong_deposit_parameters": {
    "msg": "Zk_rollup: attempted a deposit with invalid parameters",
    "category": "temporary"
  },
  "proto.alpha.operations.invalid_nonzero_transaction_amount": {
    "msg": "A transaction expected a zero-amount but got non-zero.",
    "category": "temporary"
  },
  "proto.alpha.operations.invalid_self_transaction_destination": {
    "msg": "A pseudo-transaction destination must equal its sender.",
    "category": "temporary"
  },
  "proto.alpha.operations.invalid_sender": {
    "msg": "Invalid sender for an internal operation restricted to implicit and originated accounts.",
    "category": "temporary"
  },
  "proto.alpha.operations.invalid_staking_parameters": {
    "msg": "The staking parameters are invalid.",
    "category": "temporary"
  },
  "proto.alpha.operations.invalid_staking_parameters_sender": {
    "msg": "The staking parameters can only be set by delegates.",
    "category": "temporary"
  },
  "proto.alpha.operations.invalid_transfer_to_smart_rollup_from_implicit_account": {
    "msg": "Invalid transfer to smart rollup from implicit account",
    "category": "temporary"
  },
  "proto.alpha.operations.stake_modification_with_no_delegate_set": {
    "msg": "(Un)Stake operations are only allowed when delegate is set.",
    "category": "temporary"
  },
  "proto.alpha.operations.staking_for_delegator_while_external_staking_disabled": {
    "msg": "As long as external staking is not enabled, staking operations are only allowed from delegates.",
    "category": "temporary"
  },
  "proto.alpha.operations.staking_to_delegate_that_refuses_external_staking": {
    "msg": "The delegate currently does not accept staking operations from sources other than itself: its `limit_of_staking_over_baking` parameter is set to 0.",
    "category": "temporary"
  },
  "proto.alpha.out_of_bound_issuance_bonus": {
    "msg": "Computed issuance bonus is out of bound",
    "category": "temporary"
  },
  "proto.alpha.outdated_fitness": {
    "msg": "Outdated fitness: referring to a previous version",
    "category": "temporary"
  },
  "proto.alpha.period_overflow": {
    "msg": "Last operation generated an integer overflow.",
    "category": "temporary"
  },
  "proto.alpha.prefilter.Consensus_operation_in_far_future": {
    "msg": "Consensus operation too far in the future are not accepted.",
    "category": "temporary"
  },
  "proto.alpha.prefilter.fees_too_low": {
    "msg": "Operation fees are too low",
    "category": "temporary"
  },
  "proto.alpha.prefilter.wrong_operation": {
    "msg": "Failing_noop operations are not accepted in the mempool.",
    "category": "temporary"
  },
  "proto.alpha.raw_context.consensus.slot_map_not_found": {
    "msg": "Pre-computed map by first slot not found.",
    "category": "temporary"
  },
  "proto.alpha.rollup.error_zk_rollup_address_generation": {
    "msg": "Error while generating rollup address",
    "category": "temporary"
  },
  "proto.alpha.round_of_past_timestamp": {
    "msg": "Provided timestamp is before the expected level start.",
    "category": "temporary"
  },
  "proto.alpha.round_overflow": {
    "msg": "Round cannot be built out of integer greater than maximum int32 value.",
    "category": "temporary"
  },
  "proto.alpha.round_too_high": {
    "msg": "block round too high.",
    "category": "temporary"
  },
  "proto.alpha.run_operation_does_not_support_consensus_operations": {
    "msg": "The run_operation RPC does not support consensus operations.",
    "category": "temporary"
  },
  "proto.alpha.sampler_already_set": {
    "msg": "Internal error: Raw_context.set_sampler_for_cycle was called twice for a given cycle",
    "category": "temporary"
  },
  "proto.alpha.seed.unknown_seed": {
    "msg": "The requested seed is not available",
    "category": "temporary"
  },
  "proto.alpha.slot.invalid_slot": {
    "msg": "Invalid slot",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_add_zero_messages": {
    "msg": "Tried to add zero messages to a smart rollup",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_address_generation": {
    "msg": "Error while generating a smart rollup address",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_arith_invalid_claim_about_outbox": {
    "msg": "Invalid claim about outbox",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_arith_output_proof_production_failed": {
    "msg": "Output proof production failed",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_arith_proof_production_failed": {
    "msg": "Proof production failed",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_bad_commitment_serialization": {
    "msg": "Unable to hash the commitment serialization.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_bad_inbox_level": {
    "msg": "Attempted to commit to a bad inbox level.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_commitment_disputed": {
    "msg": "Attempted to cement a disputed commitment.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_commitment_from_future": {
    "msg": "Commitment inbox level is greater or equal than current level",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_commitment_past_curfew": {
    "msg": "A commitment exists for this inbox level for longer than the curfew period.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_commitment_too_old": {
    "msg": "Published commitment is too old",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_commitment_too_recent": {
    "msg": "Attempted to cement a commitment before its refutation deadline.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_dissection_edge_ticks_mismatch": {
    "msg": "Mismatch in the edge ticks of the dissection",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_dissection_invalid_distribution": {
    "msg": "Ticks should only increase in dissection",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_dissection_invalid_number_of_sections": {
    "msg": "Invalid number of sections in the dissection",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_dissection_invalid_successive_states_shape": {
    "msg": "Cannot recover from a blocked state in a dissection",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_dissection_number_of_sections_mismatch": {
    "msg": "Mismatch in the number of sections in the dissection",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_dissection_start_hash_mismatch": {
    "msg": "Mismatch in the start hash of the dissection",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_dissection_stop_hash_mismatch": {
    "msg": "Mismatch in the stop hash of the dissection",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_dissection_ticks_not_increasing": {
    "msg": "Ticks should only increase in dissection",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_does_not_exist": {
    "msg": "Attempted to use a smart rollup that has not been originated.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_double_publish": {
    "msg": "No commitment to cement",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_duplicated_key_in_whitelist": {
    "msg": "No commitment to cement",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_empty_whitelist": {
    "msg": "Smart rollup whitelist cannot be empty",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_game_already_started": {
    "msg": "Refutation game already started, must play with is_opening_move = false.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_inbox_level_reached_message_limit": {
    "msg": "There can be only 1000000 messages in an inbox level, the limit has been reached.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_inbox_message_decoding": {
    "msg": "Failed to decode a smart rollup management protocol inbox message value",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_inbox_message_encoding": {
    "msg": "Failed to encode a rollup management protocol inbox message value",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_invalid_last_cemented_commitment": {
    "msg": "Invalid last-cemented-commitment",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_invalid_outbox_level": {
    "msg": "Invalid outbox level",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_invalid_outbox_message_index": {
    "msg": "Invalid rollup outbox message index",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_invalid_output_proof": {
    "msg": "Invalid output proof",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_invalid_parameters_type": {
    "msg": "Invalid parameters type for smart rollup",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_invalid_serialized_inbox_proof": {
    "msg": "The serialized inbox proof can not be de-serialized",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_management_protocol_invalid_destination": {
    "msg": "Invalid destination",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_max_number_of_messages_reached_for_commitment_period": {
    "msg": "Maximum number of messages reached for commitment period",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_maximal_number_of_parallel_games_reached": {
    "msg": "Maximal number of parallel games reached",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_no_commitment_to_cement": {
    "msg": "No commitment to cement",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_no_conflict": {
    "msg": "No conflict.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_no_game": {
    "msg": "Refutation game does not exist",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_no_stakers": {
    "msg": "No stakers for the targeted smart rollup.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_no_valid_commitment_to_cement": {
    "msg": "Attempted to cement a commitment but there is no valid commitment to cement.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_not_staked": {
    "msg": "This implicit account is not a staker of this smart rollup.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_not_staked_on_lcc_or_ancestor": {
    "msg": "Attempted to withdraw while not staked on the last cemented commitment or its ancestor.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_not_valid_commitments_conflict": {
    "msg": "Conflicting commitments do not have a common ancestor",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_outbox_level_expired": {
    "msg": "Outbox level expired",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_outbox_message_already_applied": {
    "msg": "Outbox message already applied",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_outbox_message_repr.error_decoding_outbox_message": {
    "msg": "Failed to decode a rollup management protocol outbox message value",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_outbox_message_repr.error_encoding_outbox_message": {
    "msg": "Failed to encode a rollup management protocol outbox message value",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_outdated_whitelist_update": {
    "msg": "Outdated whitelist update",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_parent_not_lcc": {
    "msg": "Parent is not the last cemented commitment.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_proof_check": {
    "msg": "An invalid proof has been submitted",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_remove_lcc_or_ancestor": {
    "msg": "Can not remove a staker committed on cemented.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_rollup_is_public": {
    "msg": "No commitment to cement",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_staker_double_stake": {
    "msg": "Staker tried to double stake.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_staker_funds_too_low": {
    "msg": "Staker doesn't have enough funds to make a smart rollup deposit.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_staker_in_game": {
    "msg": "Attempted to start a game where one staker is already busy",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_staker_not_in_whitelist": {
    "msg": "No commitment to cement",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_timeout_level_not_reached": {
    "msg": "Attempt to timeout game too early",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_too_far_ahead": {
    "msg": "Commitment is too far ahead of the last cemented commitment.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_unknown_commitment": {
    "msg": "Unknown commitment.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_wasm_invalid_claim_about_outbox": {
    "msg": "Invalid claim about outbox",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_wasm_invalid_dissection_distribution": {
    "msg": "Invalid dissection distribution: not all ticks are a multiplier of the maximum number of ticks of a snapshot",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_wasm_output_proof_production_failed": {
    "msg": "Output proof production failed",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_wasm_output_proof_verification_failed": {
    "msg": "Output proof verification failed",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_wasm_proof_production_failed": {
    "msg": "Proof production failed",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_wasm_proof_verification_failed": {
    "msg": "Proof verification failed",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_whitelist_disabled": {
    "msg": "The whitelist must be None when the feature is deactivated.",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_wrong_staker_for_conflict_commitment": {
    "msg": "Given commitment is not staked by given staker",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_wrong_turn": {
    "msg": "Attempt to play move but not staker's turn",
    "category": "temporary"
  },
  "proto.alpha.smart_rollup_zero_tick_commitment": {
    "msg": "Tried to publish a 0 tick commitment",
    "category": "temporary"
  },
  "proto.alpha.storage_exhausted.operation": {
    "msg": "A script or one of its callee wrote more bytes than the operation said it would",
    "category": "temporary"
  },
  "proto.alpha.storage_limit_too_high": {
    "msg": "A transaction tried to exceed the hard limit on storage",
    "category": "temporary"
  },
  "proto.alpha.tez.addition_overflow": {
    "msg": "An addition of two tez amounts overflowed",
    "category": "temporary"
  },
  "proto.alpha.tez.invalid_divisor": {
    "msg": "Multiplication of a tez amount by a non positive integer",
    "category": "temporary"
  },
  "proto.alpha.tez.multiplication_overflow": {
    "msg": "A multiplication of a tez amount by an integer overflowed",
    "category": "temporary"
  },
  "proto.alpha.tez.negative_multiplicator": {
    "msg": "Multiplication of a tez amount by a negative integer",
    "category": "temporary"
  },
  "proto.alpha.tez.subtraction_underflow": {
    "msg": "A subtraction of two tez amounts underflowed (i.e., would have led to a negative amount)",
    "category": "temporary"
  },
  "proto.alpha.timestamp_add": {
    "msg": "Overflow when adding timestamps.",
    "category": "temporary"
  },
  "proto.alpha.timestamp_sub": {
    "msg": "Subtracting timestamps resulted in negative period.",
    "category": "temporary"
  },
  "proto.alpha.too_many_internal_operations": {
    "msg": "A transaction exceeded the hard limit of internal operations it can emit",
    "category": "temporary"
  },
  "proto.alpha.undefined_operation_nonce": {
    "msg": "An origination was attempted out of the scope of a manager operation",
    "category": "temporary"
  },
  "proto.alpha.undetermined_issuance_coeff_for_cycle": {
    "msg": "Issuance coefficient is only determined for the current cycle and the next [preserved_cycles] cycles to come. Requested cycle is not in this window.",
    "category": "temporary"
  },
  "proto.alpha.unexpected_level": {
    "msg": "Level must be non-negative.",
    "category": "temporary"
  },
  "proto.alpha.unexpected_nonce_length": {
    "msg": "Nonce length is incorrect.",
    "category": "temporary"
  },
  "proto.alpha.validate.block.inconsistent_validation_passes_in_block": {
    "msg": "Validation of operation should be ordered by their validation passes in a block.",
    "category": "temporary"
  },
  "proto.alpha.validate.block.insufficient_locked_round_evidence": {
    "msg": "Insufficient locked round evidence.",
    "category": "temporary"
  },
  "proto.alpha.validate.block.invalid_double_baking_evidence": {
    "msg": "A double-baking evidence is inconsistent (two distinct levels)",
    "category": "temporary"
  },
  "proto.alpha.validate.block.invalid_payload_hash": {
    "msg": "Invalid payload hash.",
    "category": "temporary"
  },
  "proto.alpha.validate.block.locked_round_after_block_round": {
    "msg": "Locked round after block round.",
    "category": "temporary"
  },
  "proto.alpha.validate.block.not_enough_attestations": {
    "msg": "The block being validated does not include the required minimum number of attestations.",
    "category": "temporary"
  },
  "proto.alpha.validate.consensus_operation_for_future_level": {
    "msg": "Consensus operation for future level.",
    "category": "temporary"
  },
  "proto.alpha.validate.consensus_operation_for_future_round": {
    "msg": "Consensus operation for future round.",
    "category": "temporary"
  },
  "proto.alpha.validate.consensus_operation_for_old_level": {
    "msg": "Consensus operation for old level.",
    "category": "temporary"
  },
  "proto.alpha.validate.consensus_operation_for_old_round": {
    "msg": "Consensus operation for old round.",
    "category": "temporary"
  },
  "proto.alpha.validate.consensus_operation_not_allowed": {
    "msg": "Consensus operation not allowed.",
    "category": "temporary"
  },
  "proto.alpha.validate.double_inclusion_of_consensus_operation": {
    "msg": "Double inclusion of consensus operation.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.already_denounced": {
    "msg": "The same denunciation has already been validated.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.already_proposed": {
    "msg": "The delegate has already submitted one of the operation's proposals.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.already_submitted_a_ballot": {
    "msg": "The delegate has already submitted a ballot for the current voting period.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.ballot_for_wrong_proposal": {
    "msg": "Ballot provided for a proposal that is not the current one.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.block.inconsistent_denunciation": {
    "msg": "A denunciation operation is inconsistent (two distinct delegates)",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.block.invalid_denunciation": {
    "msg": "A denunciation is malformed",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.block.outdated_denunciation": {
    "msg": "A denunciation is outdated.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.block.too_early_denunciation": {
    "msg": "A denunciation is too far in the future",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.conflict_too_many_proposals": {
    "msg": "The delegate exceeded the maximum number of allowed proposals due to, among others, previous Proposals operations in the current block/mempool.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.conflicting_activation": {
    "msg": "The account has already been activated by a previous operation in the current validation state.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.conflicting_ballot": {
    "msg": "The delegate has already submitted a ballot in a previously validated operation of the current block or mempool.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.conflicting_denunciation": {
    "msg": "The same denunciation has already been validated in the current validation state.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.conflicting_drain": {
    "msg": "A manager operation or another drain operation is in conflict.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.conflicting_nonce_revelation": {
    "msg": "A revelation for the same nonce has already been validated for the current validation state.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.conflicting_proposals": {
    "msg": "The current block/mempool already contains a testnest dictator proposals operation, so it cannot have any other voting operation.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.conflicting_vdf_revelation": {
    "msg": "A revelation for the same vdf has already been validated for the current validation state.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.empty_proposals": {
    "msg": "Proposal list cannot be empty.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.failing_noop_error": {
    "msg": "A failing_noop operation can never be validated.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.gas_quota_exceeded_init_deserialize": {
    "msg": "Gas limit was not high enough to deserialize the transaction parameters or origination script code or initial storage etc., making the operation impossible to parse within the provided gas bounds.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.inconsistent_counters": {
    "msg": "Inconsistent counters in operation. Counters of an operation must be successive.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.inconsistent_sources": {
    "msg": "The operation batch includes operations from different sources.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.incorrect_reveal_position": {
    "msg": "Incorrect reveal operation position in batch: only allowed in first position.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.insufficient_gas_for_manager": {
    "msg": "Gas limit is too low to cover the initial cost of manager operations: a minimum of 100 gas units is required.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.invalid_activation": {
    "msg": "The given key and secret do not correspond to any existing preallocated contract.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.manager_restriction": {
    "msg": "An operation with the same manager has already been validated in the current block.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.proposals_contain_duplicate": {
    "msg": "The list of proposals contains a duplicate element.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.source_not_in_vote_listings": {
    "msg": "The delegate is not in the vote listings.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.testnet_dictator_multiple_proposals": {
    "msg": "A testnet dictator cannot submit more than one proposal at a time.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.wrong_voting_period_index": {
    "msg": "The voting operation contains a voting period index different from the current one.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.wrong_voting_period_kind": {
    "msg": "The voting operation is incompatible the current voting period kind.",
    "category": "temporary"
  },
  "proto.alpha.validate.operation.zk_rollup_disabled": {
    "msg": "ZK rollups will be enabled in a future proposal.",
    "category": "temporary"
  },
  "proto.alpha.validate.preattestation_round_too_high": {
    "msg": "Preattestation round too high.",
    "category": "temporary"
  },
  "proto.alpha.validate.temporarily_forbidden_delegate": {
    "msg": "The delegate has committed too many misbehaviours.",
    "category": "temporary"
  },
  "proto.alpha.validate.unexpected_attestation_in_block": {
    "msg": "Unexpected attestation in block.",
    "category": "temporary"
  },
  "proto.alpha.validate.unexpected_preattestation_in_block": {
    "msg": "Unexpected preattestation in block.",
    "category": "temporary"
  },
  "proto.alpha.validate.wrong_payload_hash_for_consensus_operation": {
    "msg": "Wrong payload hash for consensus operation.",
    "category": "temporary"
  },
  "proto.alpha.validate.wrong_slot_for_consensus_operation": {
    "msg": "Wrong slot used for a preattestation or attestation.",
    "category": "temporary"
  },
  "proto.alpha.vdf.previously_revealed": {
    "msg": "Duplicate VDF revelation in cycle",
    "category": "temporary"
  },
  "proto.alpha.vdf.too_early_revelation": {
    "msg": "VDF revelation before the end of the nonce revelation period",
    "category": "temporary"
  },
  "proto.alpha.vdf.unverified_result": {
    "msg": "VDF verification failed",
    "category": "temporary"
  },
  "proto.alpha.viewCallbackOriginationFailed": {
    "msg": "View callback origination failed",
    "category": "temporary"
  },
  "proto.alpha.viewNeverReturns": {
    "msg": "A view never initiated a transaction to the given callback contract.",
    "category": "temporary"
  },
  "proto.alpha.viewNotFound": {
    "msg": "The contract does not have a view of the given name.",
    "category": "temporary"
  },
  "proto.alpha.viewUnexpectedReturn": {
    "msg": "A view initiated a list of operations while the TZIP-4 standard expects only a transaction to the given callback contract.",
    "category": "temporary"
  },
  "proto.alpha.viewedContractHasNoScript": {
    "msg": "A view was called on a contract with no script.",
    "category": "temporary"
  },
  "proto.alpha.viewerUnexpectedStorage": {
    "msg": "A VIEW instruction returned an unexpected value.",
    "category": "temporary"
  },
  "proto.alpha.wrong_fitness": {
    "msg": "Wrong fitness.",
    "category": "temporary"
  },
  "proto.alpha.zk_rollup_does_not_exist": {
    "msg": "Attempted to use a ZK rollup that has not been originated.",
    "category": "temporary"
  },
  "proto.alpha.zk_rollup_invalid_op code": {
    "msg": "Invalid op code in append",
    "category": "temporary"
  },
  "proto.alpha.zk_rollup_negative_length": {
    "msg": "Negative length for pending list prefix",
    "category": "temporary"
  },
  "proto.alpha.zk_rollup_pending_list_too_short": {
    "msg": "Pending list is too short",
    "category": "temporary"
  },
  "proto.alpha.zk_rollup_ticket_payload_size_limit_exceeded": {
    "msg": "The payload of the deposited ticket exceeded the size limit",
    "category": "temporary"
  },
  "node.bootstrap_pipeline.invalid_locator": {
    "msg": "Block locator is invalid.",
    "category": "permanent"
  },
  "node.bootstrap_pipeline.too_short_locator": {
    "msg": "Block locator is too short.",
    "category": "permanent"
  },
  "node.config.trusted_setup_not_found": {
    "msg": "No trusted setup found in the explored paths",
    "category": "permanent"
  },
  "node.mempool.rejected_by_full_mempool": {
    "msg": "Operation fees are too low to be considered in full mempool",
    "category": "temporary"
  },
  "node.mempool.removed_from_full_mempool": {
    "msg": "Operation removed from full mempool because its fees are too low",
    "category": "temporary"
  },
  "node.p2p_conn.peer_discovery_disabled": {
    "msg": "The peer discovery is disabled, sending advertise messages is forbidden.",
    "category": "permanent"
  },
  "node.p2p_connect_handler.identity_check_failure": {
    "msg": "Peer announced an identity which does not match the one specified on the command-line.",
    "category": "permanent"
  },
  "node.p2p_io_scheduler.connection_closed": {
    "msg": "IO error: connection with a peer is closed.",
    "category": "permanent"
  },
  "node.p2p_io_scheduler.connection_error": {
    "msg": "IO error: connection error while reading from a peer.",
    "category": "permanent"
  },
  "node.p2p_maintenance.disabled": {
    "msg": "Attempt to trigger the maintenance failed as the maintenance is disabled.",
    "category": "permanent"
  },
  "node.p2p_pool.connected": {
    "msg": "Fail to connect with a peer: a connection is already established.",
    "category": "permanent"
  },
  "node.p2p_pool.connection_failed": {
    "msg": "TCP connection failed (refused or no route to host).",
    "category": "permanent"
  },
  "node.p2p_pool.disabled": {
    "msg": "The P2P layer on this node is not active.",
    "category": "permanent"
  },
  "node.p2p_pool.peer_banned": {
    "msg": "The peer identity you tried to connect is banned.",
    "category": "permanent"
  },
  "node.p2p_pool.pending_connection": {
    "msg": "Fail to connect with a peer: a connection is already pending.",
    "category": "permanent"
  },
  "node.p2p_pool.point_banned": {
    "msg": "The address you tried to connect is banned.",
    "category": "permanent"
  },
  "node.p2p_pool.private_mode": {
    "msg": "Node is in private mode.",
    "category": "permanent"
  },
  "node.p2p_pool.rejected": {
    "msg": "Connection to peer was rejected by us.",
    "category": "permanent"
  },
  "node.p2p_pool.too_many_connections": {
    "msg": "Too many connections.",
    "category": "permanent"
  },
  "node.p2p_socket.decipher_error": {
    "msg": "An error occurred while deciphering.",
    "category": "permanent"
  },
  "node.p2p_socket.decoding_error": {
    "msg": "An error occurred while decoding.",
    "category": "permanent"
  },
  "node.p2p_socket.invalid_auth": {
    "msg": "Rejected peer connection: invalid authentication.",
    "category": "permanent"
  },
  "node.p2p_socket.invalid_chunks_size": {
    "msg": "Size of chunks is not valid.",
    "category": "permanent"
  },
  "node.p2p_socket.invalid_incoming_ciphertext_size": {
    "msg": "The announced size for the incoming ciphertext is invalid.",
    "category": "permanent"
  },
  "node.p2p_socket.invalid_message_size": {
    "msg": "The size of the message to be written is invalid.",
    "category": "permanent"
  },
  "node.p2p_socket.myself": {
    "msg": "Remote peer is actually yourself.",
    "category": "permanent"
  },
  "node.p2p_socket.not_enough_proof_of_work": {
    "msg": "Remote peer cannot be authenticated: not enough proof of work.",
    "category": "permanent"
  },
  "node.p2p_socket.rejected_by_nack": {
    "msg": "Rejected peer connection: The peer rejected the socket connection by Nack with a list of alternative peers.",
    "category": "permanent"
  },
  "node.p2p_socket.rejected_no_common_protocol": {
    "msg": "Rejected peer connection: rejected socket connection as we have no common network protocol with the peer.",
    "category": "permanent"
  },
  "node.p2p_socket.rejected_socket_connection": {
    "msg": "Rejected peer connection: rejected socket connection.",
    "category": "permanent"
  },
  "node.p2p_socket.rejecting_incoming": {
    "msg": "Rejecting peer connection with motive.",
    "category": "permanent"
  },
  "node.peer_validator.known_invalid": {
    "msg": "Known invalid block found in the peer's chain",
    "category": "permanent"
  },
  "node.peer_validator.unknown_ancestor": {
    "msg": "Unknown ancestor block found in the peer's chain",
    "category": "permanent"
  },
  "node.prevalidation.future_block_header": {
    "msg": "The block was annotated with a time too far in the future.",
    "category": "temporary"
  },
  "node.prevalidation.oversized_operation": {
    "msg": "The operation size is bigger than allowed.",
    "category": "permanent"
  },
  "node.prevalidation.parse_error": {
    "msg": "Raised when an operation has not been parsed correctly during prevalidation.",
    "category": "permanent"
  },
  "node.prevalidation.too_many_operations": {
    "msg": "The prevalidation context is full.",
    "category": "temporary"
  },
  "node.protocol_validator.cannot_load_protocol": {
    "msg": "Cannot load protocol from disk",
    "category": "permanent"
  },
  "node.protocol_validator.invalid_protocol": {
    "msg": "Invalid protocol.",
    "category": "permanent"
  },
  "node.state.bad_data_dir": {
    "msg": "The data directory could not be read. This could be because it was generated with an old version of the octez-node program. Deleting and regenerating this directory may fix the problem.",
    "category": "permanent"
  },
  "node.state.block.inconsistent_context_hash": {
    "msg": "When committing the context of a block, the announced context hash was not the one computed at commit time.",
    "category": "permanent"
  },
  "node.state.block.missing_block_metadata_hash": {
    "msg": "A block was expected to commit to a block metadata hash, however none was given.",
    "category": "permanent"
  },
  "node.state.block.missing_operation_metadata_hashes": {
    "msg": "A block was expected to commit to operation metadata hashes, however none were given.",
    "category": "permanent"
  },
  "node.state.block_not_invalid": {
    "msg": "The invalid block to be unmarked was not actually invalid.",
    "category": "permanent"
  },
  "node.state.unknown_chain": {
    "msg": "The chain identifier could not be found in the chain identifiers table.",
    "category": "permanent"
  },
  "node.validator.checkpoint_error": {
    "msg": "The block belongs to a branch that is not compatible with the current checkpoint.",
    "category": "branch"
  },
  "node.validator.inactive_chain": {
    "msg": "Attempted validation of a block from an inactive chain.",
    "category": "branch"
  },
  "validator.cannot_validate_while_shutting_down": {
    "msg": "Cannot validate block while the node is shutting down.",
    "category": "temporary"
  },
  "validator.inconsistent_operations_hash": {
    "msg": "The provided list of operations is inconsistent with the block header.",
    "category": "temporary"
  },
  "validator.invalid_block": {
    "msg": "Invalid block.",
    "category": "permanent"
  },
  "validator.missing_test_protocol": {
    "msg": "Missing test protocol when forking the test chain",
    "category": "temporary"
  },
  "validator.unavailable_protocol": {
    "msg": "The protocol required for validating a block is missing.",
    "category": "temporary"
  },
  "validator.validation_process_failed": {
    "msg": "Failed to validate block using external validation process.",
    "category": "temporary"
  },
  "micheline.parse_error.annotation_exceeds_max_length": {
    "msg": "While parsing a piece of Micheline source, an annotation exceeded the maximum length (255).",
    "category": "permanent"
  },
  "micheline.parse_error.empty_expression": {
    "msg": "Tried to interpret an empty piece or Micheline source as a single expression.",
    "category": "permanent"
  },
  "micheline.parse_error.extra_token": {
    "msg": "While parsing a piece of Micheline source, an extra semi colon or parenthesis was encountered.",
    "category": "permanent"
  },
  "micheline.parse_error.invalid_hex_bytes": {
    "msg": "While parsing a piece of Micheline source, a byte sequence (0x...) was not valid as a hex byte.",
    "category": "permanent"
  },
  "micheline.parse_error.invalid_utf8_sequence": {
    "msg": "While parsing a piece of Micheline source, a sequence of bytes that is not valid UTF-8 was encountered.",
    "category": "permanent"
  },
  "micheline.parse_error.misaligned_node": {
    "msg": "While parsing a piece of Micheline source, an expression was not aligned with its siblings of the same mother application or sequence.",
    "category": "permanent"
  },
  "micheline.parse_error.missing_break_after_number": {
    "msg": "While parsing a piece of Micheline source, a number was not visually separated from its follower token, leading to misreadability.",
    "category": "permanent"
  },
  "micheline.parse_error.unclosed_token": {
    "msg": "While parsing a piece of Micheline source, a parenthesis or a brace was unclosed.",
    "category": "permanent"
  },
  "micheline.parse_error.undefined_escape_sequence": {
    "msg": "While parsing a piece of Micheline source, an unexpected escape sequence was encountered in a string.",
    "category": "permanent"
  },
  "micheline.parse_error.unexpected_character": {
    "msg": "While parsing a piece of Micheline source, an unexpected character was encountered.",
    "category": "permanent"
  },
  "micheline.parse_error.unexpected_token": {
    "msg": "While parsing a piece of Micheline source, an unexpected token was encountered.",
    "category": "permanent"
  },
  "micheline.parse_error.unterminated_comment": {
    "msg": "While parsing a piece of Micheline source, a commentX was not terminated.",
    "category": "permanent"
  },
  "micheline.parse_error.unterminated_integer": {
    "msg": "While parsing a piece of Micheline source, an integer was not terminated.",
    "category": "permanent"
  },
  "micheline.parse_error.unterminated_string": {
    "msg": "While parsing a piece of Micheline source, a string was not terminated.",
    "category": "permanent"
  },
  "rpc_client.request_failed": {
    "msg": "",
    "category": "permanent"
  },
  "Block_validator_process.applying_non_validated_block": {
    "msg": "Applying non validated block",
    "category": "permanent"
  },
  "Block_validator_process.failed_to_checkout_context": {
    "msg": "The context checkout failed using a given hash",
    "category": "permanent"
  },
  "RPC.Empty_error_list": {
    "msg": "The RPC returned with an error code but no associated error.",
    "category": "branch"
  },
  "RPC.Unexpected_error_encoding": {
    "msg": "The RPC returned with an error code, and the associated body was not a valid error trace. It is likely that the answer does not comes directly from a compatible node.",
    "category": "branch"
  },
  "RPC_context.Gone": {
    "msg": "RPC lookup failed. Block has been pruned and requested data deleted.",
    "category": "branch"
  },
  "RPC_context.Not_found": {
    "msg": "RPC lookup failed. No RPC exists at the URL or the RPC tried to access non-existent data.",
    "category": "branch"
  },
  "RPC_error.bad_version": {
    "msg": "The RPC was called with a bad version number.",
    "category": "permanent"
  },
  "Validator_process.system_error_while_validating": {
    "msg": "The validator failed because of a system error",
    "category": "temporary"
  },
  "block_store.v_3_0_upgrade_missing_floating_block": {
    "msg": "Failed to upgrade the floating store",
    "category": "permanent"
  },
  "block_validation.cannot_serialize_metadata": {
    "msg": "Unable to serialize metadata",
    "category": "permanent"
  },
  "canceled": {
    "msg": "A promise was unexpectedly canceled",
    "category": "temporary"
  },
  "cli.key.invalid_uri": {
    "msg": "A key has been provided with an invalid uri.",
    "category": "permanent"
  },
  "cli.signature_mismatch": {
    "msg": "The signer produced an invalid signature",
    "category": "permanent"
  },
  "cli.unexisting_scheme": {
    "msg": "The requested scheme does not exist",
    "category": "permanent"
  },
  "cli.unregistered_key_scheme": {
    "msg": "A key has been provided with an unregistered scheme (no corresponding plugin)",
    "category": "permanent"
  },
  "cli.wrong_key_scheme": {
    "msg": "A certain scheme type has been requested but another one was found",
    "category": "permanent"
  },
  "client.alpha.Bad deserialized counter": {
    "msg": "The byte sequence references a multisig counter that does not match the one currently stored in the given multisig contract",
    "category": "permanent"
  },
  "client.alpha.ForbiddenNegativeInt": {
    "msg": "invalid number, must a non negative natural ",
    "category": "permanent"
  },
  "client.alpha.InvalidAddressForSmartContract": {
    "msg": "Invalid input, expected a smart contract address in base58 check notation (KT1...)",
    "category": "permanent"
  },
  "client.alpha.actionDeserialisation": {
    "msg": "When trying to deserialise an action from a sequence of bytes, we got an expression that does not correspond to a known multisig action",
    "category": "permanent"
  },
  "client.alpha.actionUnwrappingError": {
    "msg": "The argument's type does not correspond to that of the corresponding FA1.2 entrypoint.",
    "category": "permanent"
  },
  "client.alpha.badDeserializedContract": {
    "msg": "When trying to deserialise an action from a sequence of bytes, we got an action for another multisig contract",
    "category": "permanent"
  },
  "client.alpha.badMaxPriorityArg": {
    "msg": "invalid priority in -max-priority",
    "category": "permanent"
  },
  "client.alpha.badMaxWaitingTimeArg": {
    "msg": "invalid duration in -max-waiting-time",
    "category": "permanent"
  },
  "client.alpha.badMinimalFeesArg": {
    "msg": "invalid fee threshold in -fee-threshold",
    "category": "permanent"
  },
  "client.alpha.badPreservedLevelsArg": {
    "msg": "invalid number of levels in -preserved-levels",
    "category": "permanent"
  },
  "client.alpha.badTezArg": {
    "msg": "Invalid ꜩ notation in parameter.",
    "category": "permanent"
  },
  "client.alpha.bytesDeserialisation": {
    "msg": "When trying to deserialise an action from a sequence of bytes, we got an error",
    "category": "permanent"
  },
  "client.alpha.contractHasNoScript": {
    "msg": "A multisig command has referenced a scriptless smart contract instead of a multisig smart contract.",
    "category": "permanent"
  },
  "client.alpha.contractHasNoStorage": {
    "msg": "A multisig command has referenced a smart contract without storage instead of a multisig smart contract.",
    "category": "permanent"
  },
  "client.alpha.contractHasUnexpectedStorage": {
    "msg": "A multisig command has referenced a smart contract whose storage is of a different shape than the expected one.",
    "category": "permanent"
  },
  "client.alpha.contractWithoutCode": {
    "msg": "Attempt to get the code of a contract failed because it has nocode. No scriptless contract should remain.",
    "category": "permanent"
  },
  "client.alpha.entrypointMismatch": {
    "msg": "An FA1.2 command has referenced a smart contract whose script does not implement at least one FA1.2 entrypoint, or with an incompatible type. See TZIP-7 (https://gitlab.com/tezos/tzip/-/blob/master/proposals/tzip-7/tzip-7.md) for documentation on FA1.2.",
    "category": "permanent"
  },
  "client.alpha.fa12ContractHasNoScript": {
    "msg": "An FA1.2 command has referenced a scriptless contract.",
    "category": "permanent"
  },
  "client.alpha.fa12ContractHasNoStorage": {
    "msg": "An FA1.2 command made a call on a contract that has no storage.",
    "category": "permanent"
  },
  "client.alpha.fa12UnexpectedError": {
    "msg": "An unexpected Michelson error was reached during the interpretation of an FA1.2 contract.",
    "category": "permanent"
  },
  "client.alpha.illTypedArgumentForMultisig": {
    "msg": "The provided argument for a transfer from a multisig contract is ill-typed",
    "category": "permanent"
  },
  "client.alpha.illTypedLambdaForMultisig": {
    "msg": "The provided lambda for a transfer from a multisig contract is ill-typed",
    "category": "permanent"
  },
  "client.alpha.invalidSignature": {
    "msg": "A signature was given for a multisig contract that matched none of the public keys of the contract signers",
    "category": "permanent"
  },
  "client.alpha.michelson.macros.bas_arity": {
    "msg": "A wrong number of arguments was provided to a macro",
    "category": "permanent"
  },
  "client.alpha.michelson.macros.sequence_expected": {
    "msg": "An macro expects a sequence, but a sequence was not provided",
    "category": "permanent"
  },
  "client.alpha.michelson.macros.unexpected_annotation": {
    "msg": "A macro had an annotation, but no annotation was permitted on this macro.",
    "category": "permanent"
  },
  "client.alpha.michelson.stack.wrong_stack": {
    "msg": "Failed to parse a typed stack.",
    "category": "permanent"
  },
  "client.alpha.michelson.stack.wrong_stack_item": {
    "msg": "Failed to parse an item in a typed stack.",
    "category": "permanent"
  },
  "client.alpha.michelson.wrong_extra_big_maps": {
    "msg": "Failed to parse a description of extra big maps.",
    "category": "permanent"
  },
  "client.alpha.michelson.wrong_extra_big_maps_item": {
    "msg": "Failed to parse an item in a description of extra big maps.",
    "category": "permanent"
  },
  "client.alpha.michelson.wrong_other_contracts": {
    "msg": "Failed to parse a description of other contracts.",
    "category": "permanent"
  },
  "client.alpha.michelson.wrong_other_contracts_item": {
    "msg": "Failed to parse an item in a description of other contracts.",
    "category": "permanent"
  },
  "client.alpha.nonPositiveThreshold": {
    "msg": "A multisig threshold should be a positive number",
    "category": "permanent"
  },
  "client.alpha.notASupportedMultisigContract": {
    "msg": "A multisig command has referenced a smart contract whose script is not one of the known multisig contract scripts.",
    "category": "permanent"
  },
  "client.alpha.notAViewableEntrypoint": {
    "msg": "A transaction made a call on an entrypoint expecting it to implement the 'view' type.",
    "category": "permanent"
  },
  "client.alpha.notAnEntrypoint": {
    "msg": "The parameter value of the contract call refers to a non-existing entrypoint.",
    "category": "permanent"
  },
  "client.alpha.notEnoughAllowance": {
    "msg": "An FA1.2 transfer failed because the receiver does not have enough allowance to ask for a transfer from the sender.",
    "category": "permanent"
  },
  "client.alpha.notEnoughBalance": {
    "msg": "An FA1.2 transfer failed because the sender does not have enough balance.",
    "category": "permanent"
  },
  "client.alpha.notEnoughSignatures": {
    "msg": "To run an action on a multisig contract, you should provide at least as many signatures as indicated by the threshold stored in the multisig contract.",
    "category": "permanent"
  },
  "client.alpha.thresholdTooHigh": {
    "msg": "The given threshold is higher than the number of keys, this would lead to a frozen multisig contract",
    "category": "permanent"
  },
  "client.alpha.tzt.duplicated_toplevel": {
    "msg": "A toplevel TZT primitive was used several times.",
    "category": "permanent"
  },
  "client.alpha.tzt.invalid_format": {
    "msg": "Invalid format for a TZT toplevel primitive",
    "category": "permanent"
  },
  "client.alpha.tzt.invalid_toplevel": {
    "msg": "Invalid format for a TZT toplevel entry",
    "category": "permanent"
  },
  "client.alpha.tzt.missing_mandatory": {
    "msg": "A mandatory toplevel TZT primitive was missing.",
    "category": "permanent"
  },
  "client.alpha.tzt.unknown_toplevel": {
    "msg": "A toplevel TZT primitive was unknown.",
    "category": "permanent"
  },
  "client.alpha.tzt.wrong_toplevel_arity": {
    "msg": "A known toplevel TZT primitive was used with a bad arity.",
    "category": "permanent"
  },
  "client.alpha.unsafeAllowanceChange": {
    "msg": "An FA1.2 non-zero allowance change failed because the current allowance is non-zero. For more explanation on why such allowance change is unsafe, please look at TZIP-7 (https://gitlab.com/tezos/tzip/-/blob/master/proposals/tzip-7/tzip-7.md#approve).",
    "category": "permanent"
  },
  "client.alpha.unsupportedGenericMultisigFeature": {
    "msg": "This multisig contract does not feature calling contracts with arguments",
    "category": "permanent"
  },
  "client.alpha.unsupportedGenericMultisigFeatureTy": {
    "msg": "This multisig contract does not feature calling contracts with arguments",
    "category": "permanent"
  },
  "client.alpha.unsupportedGenericMultisigLambda": {
    "msg": "This multisig contract does not feature running lambdas",
    "category": "permanent"
  },
  "config_file.cannot_switch_history_mode": {
    "msg": "Cannot switch history mode.",
    "category": "permanent"
  },
  "context_hash.unsupported_version": {
    "msg": "Unsupported context hash version.",
    "category": "permanent"
  },
  "dal.node.initialisation_twice": {
    "msg": "DAL parameters were initialised twice",
    "category": "permanent"
  },
  "dal.node.invalid_precomputation_hash": {
    "msg": "Unexpected precomputation hash",
    "category": "permanent"
  },
  "dal.node.trusted_setup_loading_failed": {
    "msg": "Trusted setup failed to load",
    "category": "permanent"
  },
  "decoding_error": {
    "msg": "Error while decoding a value",
    "category": "permanent"
  },
  "encoding_error": {
    "msg": "Error while encoding a value for a socket",
    "category": "permanent"
  },
  "failure": {
    "msg": "Exception safely wrapped in an error",
    "category": "temporary"
  },
  "injection_operation_error_case": {
    "msg": "The injection of this operation failed. The error trace are the following errors in this list.",
    "category": "permanent"
  },
  "injection_operation_succeed_case": {
    "msg": "The injection of this operation succeed among a list of injections containing at least one error.",
    "category": "permanent"
  },
  "injection_operations_error": {
    "msg": "While injecting several operations at once, one or several injections failed.",
    "category": "permanent"
  },
  "internal-event-activation-error": {
    "msg": "Activation of an Internal Event SINK with an URI failed",
    "category": "permanent"
  },
  "prevalidation.operation_conflict": {
    "msg": "The operation cannot be added because the mempool already contains a conflicting operation.",
    "category": "temporary"
  },
  "prevalidation.operation_replacement": {
    "msg": "The operation has been replaced.",
    "category": "temporary"
  },
  "snapshots.inconsistent_operation_hashes": {
    "msg": "The operations given do not match their hashes.",
    "category": "permanent"
  },
  "socket.unexpected_size_of_decoded_value": {
    "msg": "A decoded value comes from a buffer of unexpected size.",
    "category": "permanent"
  },
  "store.bad_head_invariant": {
    "msg": "Bad invariant during Store.set_head",
    "category": "permanent"
  },
  "store.bad_level": {
    "msg": "Read a block at level past our current head.",
    "category": "permanent"
  },
  "store.bad_ordering_invariant": {
    "msg": "The ordering invariant does not hold",
    "category": "permanent"
  },
  "store.block_not_found": {
    "msg": "Block not found",
    "category": "permanent"
  },
  "store.cannot_cement_blocks": {
    "msg": "Cannot cement blocks",
    "category": "temporary"
  },
  "store.cannot_cement_blocks_metadata": {
    "msg": "Cannot cement blocks metadata",
    "category": "temporary"
  },
  "store.cannot_checkout_context": {
    "msg": "Failed to checkout context",
    "category": "temporary"
  },
  "store.cannot_encode_block": {
    "msg": "Failed to encode block",
    "category": "temporary"
  },
  "store.cannot_find_chain_dir": {
    "msg": "Cannot find chain dir while upgrading storage",
    "category": "permanent"
  },
  "store.cannot_find_protocol": {
    "msg": "Cannot find protocol",
    "category": "temporary"
  },
  "store.cannot_fork_testchain": {
    "msg": "Failed to fork testchain",
    "category": "temporary"
  },
  "store.cannot_instanciate_temporary_floating_store": {
    "msg": "Cannot instanciate temporary floating store",
    "category": "temporary"
  },
  "store.cannot_load_degraded_store": {
    "msg": "Cannot load a degraded block store.",
    "category": "permanent"
  },
  "store.cannot_load_testchain": {
    "msg": "Failed to load the testchain",
    "category": "temporary"
  },
  "store.cannot_merge_store": {
    "msg": "Cannot merge the store.",
    "category": "permanent"
  },
  "store.cannot_retrieve_savepoint": {
    "msg": "Failed to retrieve savepoint",
    "category": "temporary"
  },
  "store.cannot_set_target": {
    "msg": "The given block to be set as target is invalid.",
    "category": "temporary"
  },
  "store.cannot_store_block": {
    "msg": "Failed to store block",
    "category": "temporary"
  },
  "store.cannot_update_floating_store": {
    "msg": "Cannot update floating store",
    "category": "temporary"
  },
  "store.cannot_write_in_readonly": {
    "msg": "Cannot write data in store when in readonly",
    "category": "permanent"
  },
  "store.corrupted_store": {
    "msg": "The store is corrupted",
    "category": "permanent"
  },
  "store.failed_to_get_live_blocks": {
    "msg": "Unable to compute live blocks from a given block.",
    "category": "permanent"
  },
  "store.failed_to_init_cemented_block_store": {
    "msg": "Failed to initialize the cemented block store",
    "category": "temporary"
  },
  "store.fork_testchain_not_allowed": {
    "msg": "Forking the test chain is not allowed",
    "category": "temporary"
  },
  "store.inconsistent_block_hash": {
    "msg": "Inconsistent block hash found",
    "category": "temporary"
  },
  "store.inconsistent_block_predecessor": {
    "msg": "Inconsistent block predecessor",
    "category": "temporary"
  },
  "store.inconsistent_cemented_file": {
    "msg": "Failed to read a cemented file",
    "category": "temporary"
  },
  "store.inconsistent_cemented_store": {
    "msg": "Failed to check indexes consistency",
    "category": "temporary"
  },
  "store.inconsistent_cementing_highwatermark": {
    "msg": "The stored cementing highwatermark is inconsistent with the store.",
    "category": "permanent"
  },
  "store.inconsistent_chain_store": {
    "msg": "Failed to load chain store",
    "category": "temporary"
  },
  "store.inconsistent_genesis": {
    "msg": "The given genesis block is inconsistent with the store.",
    "category": "permanent"
  },
  "store.inconsistent_history_mode": {
    "msg": "The history mode does not correspond to the store.",
    "category": "permanent"
  },
  "store.inconsistent_protocol_commit_info": {
    "msg": "Inconsistent protocol commit info while restoring snapshot",
    "category": "temporary"
  },
  "store.invalid_blocks_to_cement": {
    "msg": "Invalid block list to cement",
    "category": "temporary"
  },
  "store.invalid_genesis_marking": {
    "msg": "Cannot mark genesis as invalid",
    "category": "temporary"
  },
  "store.invalid_head_switch": {
    "msg": "The given head is not consistent with the current store's savepoint",
    "category": "permanent"
  },
  "store.merge_already_running": {
    "msg": "The store's merge is already running",
    "category": "temporary"
  },
  "store.merge_error": {
    "msg": "Error while merging the store",
    "category": "temporary"
  },
  "store.metadata_not_found": {
    "msg": "Block metadata not found",
    "category": "permanent"
  },
  "store.missing_activation_block": {
    "msg": "Missing activation block while restoring snapshot",
    "category": "temporary"
  },
  "store.missing_commit_info": {
    "msg": "Failed to retreive commit info",
    "category": "temporary"
  },
  "store.missing_last_allowed_fork_level_block": {
    "msg": "Current head's last allowed fork level block (or its associated metadata) cannot be found in the store.",
    "category": "temporary"
  },
  "store.protocol_not_found": {
    "msg": "Protocol not found",
    "category": "permanent"
  },
  "store.resulting_context_hash_not_found": {
    "msg": "Resulting context hash not found",
    "category": "permanent"
  },
  "store.target_mismatch": {
    "msg": "Target is reached but it is not a head's ancestor.",
    "category": "permanent"
  },
  "store.temporary_cemented_file_exists": {
    "msg": "The temporary cemented file already exists",
    "category": "temporary"
  },
  "store.unexpected_missing_activation_block": {
    "msg": "An activation block is unexpectedly missing from the store.",
    "category": "permanent"
  },
  "store.unexpected_missing_block": {
    "msg": "A block is unexpectedly missing from the store.",
    "category": "permanent"
  },
  "store.unexpected_missing_block_metadata": {
    "msg": "A block's metadata is unexpectedly missing from the store.",
    "category": "permanent"
  },
  "store.unexpected_missing_protocol": {
    "msg": "A protocol is unexpectedly missing from the store.",
    "category": "permanent"
  },
  "store.wrong_floating_kind_swap": {
    "msg": "Try to swap wrong floating store kind",
    "category": "temporary"
  },
  "store.wrong_predecessor": {
    "msg": "Failed to get block's predecessor",
    "category": "temporary"
  },
  "unexpected_size_of_encoded_value": {
    "msg": "An encoded value is not of the expected size.",
    "category": "permanent"
  },
  "utils.Timeout": {
    "msg": "Timeout",
    "category": "temporary"
  }
};