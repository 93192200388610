<div class="flex-row dropdown" [class.expanded]="isOpen" (click)="toggleDropdown()" [id]="ecmpId">
  <img class="button icon-torus"
    [src]="torusVerifier === '' ? 'assets/img/tezos-xtz-logo.svg' :  'assets/img/' + torusVerifier.toLowerCase() + '-logo.svg'" />
  <input readonly [(ngModel)]="torusVerifierName" /><img class="button icon-db"
    src="../../../assets/img/down-arrow.svg" />
  <div class="flex-column dropdown-content">
    <a [class.selected]="torusVerifierName === 'Tezos Address'"
      (click)="torusVerifier = ''; torusVerifierName = 'Tezos Address';">
      Tezos Address
    </a>
    <a [class.selected]="torusVerifierName === 'Tezos Domains'"
      (click)="torusVerifier = 'domain'; torusVerifierName = 'Tezos Domains'">
      Tezos Domains
    </a>
    <ng-container *ngFor="let key of torusService.verifierMapKeys">
      <ng-container *ngIf="torusService.verifierMap[key].lookups">
        <a [class.selected]="torusVerifierName === torusService.verifierMap[key].name"
          (click)="torusVerifier = key; torusVerifierName = torusService.verifierMap[key].name">
          {{ torusService.verifierMap[key].name }}
        </a>
      </ng-container>
    </ng-container>
  </div>
</div>