<div class="flex-column settings">
  <a><button class="flex-row back" [routerLink]="'/account/' + activeAccount?.address"><img
        src="../../../../../assets/img/left-arrow.svg">BACK</button></a>
  <h3>Connect with dApps</h3>
  <div class="seperator"></div>
  <div class="flex-column handler">
    <div>
      <h4>Connect to dApps on <u>another device</u> by scanning a QR code.</h4>
    </div>
    <div class="scan-button">
      <app-qr-scanner></app-qr-scanner>
    </div>
  </div>
  <ng-container *ngIf="walletConnectService.pairings?.size || walletConnectService.sessions?.size">
    <h3 style="height: 1.5rem">Wallet Connect</h3>
    <div class="seperator"></div>
    <div style="margin-left: 1rem">
      <ng-container>
        <ng-container *ngIf="walletConnectService.pairings.size">
          <h4>Pairings <span class="blue-click"
              (click)="walletConnectService.pairings.expanded = !walletConnectService.pairings.expanded">&nbsp;{{
              walletConnectService.pairings.expanded ? '&ndash;' : '+'}}&nbsp;</span>
            <ng-container
              *ngIf="walletConnectService.pairings.size">({{walletConnectService.pairings.size}})</ng-container>
          </h4>
          <table *ngIf="walletConnectService.pairings.expanded">
            <tbody class="wc pairings">
              <ng-container *ngFor="let dapp of walletConnectService.pairings.dapp | keyvalue">
                <tr class="dapp">
                  <td>{{ dapp.key }}<ng-container *ngIf="dapp?.value?.p?.length > 1">&nbsp;<span class="blue-click"
                        (click)="walletConnectService.pairings.dapp[dapp.key].expanded = !walletConnectService.pairings.dapp[dapp.key].expanded">
                        {{ dapp?.value?.expanded ? '&ndash;' : '+' }}
                      </span>
                      <ng-container *ngIf="walletConnectService.pairings?.dapp[dapp.key].p?.length > 1">
                        ({{walletConnectService.pairings.dapp[dapp.key].p.length}})</ng-container>
                    </ng-container>
                  </td>
                  <td><img (click)="walletConnectService.deletePairings(dapp?.value?.p)"
                      src="../../../assets/img/wc_delete.svg"></td>
                </tr>
                <ng-container *ngIf="dapp?.value?.expanded && dapp?.value?.p?.length > 1">
                  <tr *ngFor="let p of dapp?.value?.p | keyvalue: unsorted">
                    <td>&emsp;#{{ p.key }}</td>
                    <td><img (click)="walletConnectService.deletePairings([p?.value])"
                        src="../../../assets/img/wc_delete.svg"></td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </ng-container>
        <ng-container *ngIf="walletConnectService.sessions.size">
          <h4>Sessions <span class="blue-click"
              (click)="walletConnectService.sessions.expanded = !walletConnectService.sessions.expanded">&nbsp;{{
              walletConnectService.sessions.expanded ? '&ndash;' : '+'}}&nbsp;</span>
            <ng-container
              *ngIf="walletConnectService.sessions.size">({{walletConnectService.sessions.size}})</ng-container>
          </h4>
          <table *ngIf="walletConnectService.sessions.expanded">
            <tbody class="wc sessions">
              <ng-container *ngFor="let dapp of walletConnectService.sessions.dapp | keyvalue">
                <tr class="dapp">
                  <td>{{ dapp.key }}<ng-container *ngIf="dapp?.value?.s?.length > 1">&nbsp;<span class="blue-click"
                        (click)="walletConnectService.sessions.dapp[dapp.key].expanded = !walletConnectService.sessions.dapp[dapp.key].expanded">
                        {{ dapp?.value?.expanded ? '&ndash;' : '+' }}
                      </span>
                      <ng-container *ngIf="walletConnectService.sessions?.dapp[dapp.key].s?.length > 1">
                        ({{walletConnectService.sessions.dapp[dapp.key].s.length}})</ng-container>
                    </ng-container>
                  </td>
                  <td>
                    <ng-container *ngIf="!dapp?.value?.expanded || dapp.value.s.length === 1">{{
                      dapp.value.s[dapp.value.s.length - 1].address }}
                    </ng-container>
                  </td>
                  <td>
                    <img class="modify" (click)="changeSession(dapp.value.s[dapp.value.s.length-1])"
                      src="../../../assets/img/wc_modify.svg">
                    <img (click)="walletConnectService.deleteSessions(dapp?.value?.s)"
                      src="../../../assets/img/wc_delete.svg">
                  </td>
                </tr>
                <ng-container *ngIf="dapp?.value?.expanded && dapp?.value?.s?.length > 1">
                  <tr *ngFor="let s of dapp?.value?.s | keyvalue: unsorted">
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;#{{ s.key }}</td>
                    <td>{{s?.value?.address}}</td>
                    <td><img (click)="walletConnectService.deleteSessions([s?.value])"
                        src="../../../assets/img/wc_delete.svg">
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <h3>Peers</h3>
  <div class="seperator"></div>
  <table *ngIf="beaconService.peers.length">
    <thead>
      <tr>
        <th>Name</th>
        <th>Relay server</th>
        <th><span (click)="beaconService.removePeers()">Remove&nbsp;all</span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let peer of beaconService.peers; index as i">
        <td>{{ peer.name }}</td>
        <td>{{ peer.relayServer }}</td>
        <td (click)="beaconService.removePeer(i)"><img (click)="beaconService.removePeer(i)"
            src="../../../assets/img/cross.svg"></td>
      </tr>
    </tbody>
  </table>
  <h3>Permissions</h3>
  <div class="seperator"></div>
  <table *ngIf="beaconService.permissions.length">
    <thead>
      <tr>
        <th>Name</th>
        <th>Address</th>
        <th><span (click)="beaconService.removePermissions()">Remove&nbsp;all</span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let permission of beaconService.permissions; index as i"
        [class.inactive]="!accountAvailable(permission.address)">
        <td>{{ permission.appMetadata.name }}</td>
        <td>{{ formatAddress(permission.address) }}</td>
        <td><img (click)="beaconService.removePermission(i)" src="../../../assets/img/cross.svg"></td>
      </tr>
    </tbody>
  </table>
  <h3>Tokens<span style="width: 100%"></span><ng-container *ngIf="canRevealMnemonic()">Backup</ng-container></h3>
  <div class="seperator"></div>
  <!--<button class="rescan" (click)="rescan()">Rescan unknown tokens</button>-->
  <div class="button-wrapper">
    <button id="all" class="rescan" (click)="rescanAll()">Clear all token metadata</button>
    <button *ngIf="canRevealMnemonic()" class="reveal-mnemonic" (click)="revealMnemonic()">Reveal Seed Words</button>  
  </div>
</div>