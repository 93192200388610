import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['../../../../../../scss/components/views/start/agreement/terms-of-use/terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
