<div *ngIf="isOpen" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-row top">
      <div class="flex-row button close" (click)="closeModalAction()"><img class="x"
          src="../../../assets/img/close-btn-x.png" /></div>
      <h1 class="title">Confirmation</h1>
    </div>
    <div class="flex-column confirm-send" [class.image]="tokenBalanceService.isNFT(token)" [class.batch]="parameters">
      <form autocomplete="off">
        <div class="flex-row top">
          <div class="flex-column left" [style.minHeight]="!tokenBalanceService.isNFT(token) ? 'unset' : ''">
            <span *ngIf="!tokenBalanceService.isNFT(token)" class="key">SEND AMOUNT:</span>
            <app-asset class="token-image" [class.balance]="!tokenBalanceService.isNFT(token)"
              [assets]="token ? {displayAsset: token?.displayAsset, thumbnailAsset: token?.thumbnailAsset} : {thumbnailAsset: 'assets/img/tezos-xtz-logo.svg'}"
              [priorityList]="['thumbnailAsset', 'displayAsset']" [class.sd]="!token"></app-asset>
          </div>
          <div class="flex-column right">
            <span class="flex-row amount"><span class="tez value">{{ formatAmount(token, totalAmount(),
                false)
                }}</span>
              <ng-container *ngIf="!tokenTransfer"><span class="fiat key">USD: <span class="value">{{'$' +
                    (totalAmount()
                    *
                    walletService.wallet.XTZrate | number:'1.2-2')
                    }}</span></span>
              </ng-container>
            </span>
            <div class="flex-column items">
              <span *ngIf="tokenBalanceService.isNFT(token)" class="key amount-quantity">QUANTITY:&nbsp;&nbsp;<span
                  class="value">{{ getQuantity(totalAmount())
                  }}</span></span>
              <span class="key">FEE:&nbsp;&nbsp;<span class="value">{{ (getTotalFee() | number:'1.0-6') + ' tez'
                  }}</span></span>
              <span *ngIf="getTotalBurn(); let totalBurn" class="key">STORAGE COST:&nbsp;<span class="value">{{
                  (totalBurn
                  |
                  number:'1.0-6') + ' tez'
                  }}</span></span>
            </div>
          </div>
        </div>
        <div class="flex-row from">
          <div class="flex-column left">
            <span class="key">FROM: </span>
          </div>
          <div class="flex-column right">
            <div *ngIf="getUsername(); let displayName" class="flex-row torus-name"><img class="verifier-logo"
                src="../../../assets/img/{{ getVerifier() }}-logo.svg">{{ displayName }}</div>
            <span class="address">{{
              activeAccount?.address
              }}</span>
          </div>
        </div>
        <div *ngIf="!parameters" class="flex-row divider">
          <span></span><img class="icon" src="../../../assets/img/send-down-arrow.png" /><span></span>
        </div>
        <div class="flex-row to">
          <div class="flex-column left">
            <span class="key">SEND TO<ng-container *ngIf="parameters && transactions?.length > 1">&nbsp;BATCH
              </ng-container>: </span>
          </div>
          <div class="flex-column right">
            <div *ngFor="let transaction of transactions | slice:0:showAll; index as i"
              (click)="updateParameters(i, transaction.parameters)" class="flex-column transaction">
              <span *ngIf="transaction?.meta && transaction.meta?.verifier" class="flex-row torus-to value"><img
                  src="../../../assets/img/{{ transaction.meta.verifier }}-logo.svg" class="icon">
                {{ transaction.meta?.alias }}</span>
              <span class="flex-row single-to" [class.default]="transactions.length === 1"
                [class.selectable]="transactions.length > 1 && parameters"
                [class.active]="batchParamIndex === i && transactions.length > 1 && parameters"><span>{{
                  transaction?.destination
                  }}</span></span>
              <p class="previewAttention" *ngIf="tokenTransfer && transaction?.destination.slice(0, 3) === 'KT1'">
                Warning:
                Verify the contract can manage tokens</p>
            </div>
            <div *ngIf="transactions?.length > 10" class="button show-all"
              (click)="showAll = showAll === 10 ? undefined : 10">{{ showAll === 10 ? 'Show All' : 'Show Less' }}</div>
          </div>
        </div>
        <br />
        <br />
        <span *ngIf="parameters && transactions.length > 1" class="flex-row transfer-amount"><span class="key">TRANSFER
            AMOUNT: </span><span class="key">{{ (transactions[batchParamIndex]?.amount | number:'1.0-6')
            }}</span></span>
        <div *ngIf="parameters && parameters?.entrypoint" class="flex-row contract-call"><span class="key">CONTRACT
            CALL:
          </span><span class="key">{{ parameters?.entrypoint }}</span></div>
        <app-ui-toggle-advanced style="width: calc(100% - 1em);margin: 0 0 2.5em 0;" *ngIf="externalReq"
          [default]="false" (stateChange)="advancedForm = $event">
        </app-ui-toggle-advanced>
      </form>
      <div *ngIf="advancedForm" class="advanced-form">
        <div class="flex-column parms-container">
          <ng-container *ngIf="parameters">
            <div class="tabs-container">
              <ul class="flex-row tabs">
                <li class="tab" [class.active]="parametersFormat === 0" (click)="setParametersFormat(0)">Micheline</li>
                <li class="tab" [class.active]="parametersFormat === 1" (click)="setParametersFormat(1)">Tabular</li>
              </ul>
            </div>
            <textarea *ngIf="parameters && parametersFormat === 0" name="none" rows="10"
              readonly>{{ micheline.value || '' }}</textarea>
            <ul *ngIf="parameters && parametersFormat === 1" class="encoded-parms">
              <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: transformedParameters, level: 0 }">
              </ng-container>
            </ul>
            <ng-template #recursiveListTmpl let-list="list" let-level="level">
              <li *ngFor="let item of list" [class]="item?.children?.length ? 'expanded' : ''"
                [style.marginLeft]="!level ? '0em' : '3em'">
                <ng-container *ngIf="item?.children">
                  <span [class.key]="item.children.length ? true : false"
                    (click)="item.children.length ? toggleTab($event) : null">{{ item.key }}{{item.key ? ': ' : ' '}}{{
                    (item.val ?? '') }}</span>
                  <svg *ngIf="item.children.length" (click)="toggleTab($event)" version="1.2" baseProfile="tiny-ps"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 13" width="20" height="13">
                    <g id="1">
                      <g id="NFT">
                        <g id="Line 1">
                          <g id="Line">
                            <path id="Rectangle 4" class="shp0"
                              d="M2.86 -0.02L12.86 10.1L10 12.99L0.01 2.88L2.86 -0.02ZM17.14 -0.02L19.99 2.88L10 12.99L7.14 10.1L17.14 -0.02Z" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <ul *ngIf="item.children && item.children.length > 0">
                    <ng-container
                      *ngTemplateOutlet="recursiveListTmpl; context:{ list: item.children, level: level+3 }">
                    </ng-container>
                  </ul>
                </ng-container>
              </li>
            </ng-template>
          </ng-container>
        </div>
        <form autocomplete="off" *ngIf="externalReq && (!walletService.isLedgerWallet()) || ledgerError">
          <div class="flex-row">
            <div class="flex-column">
              <label class="label">Gas limit</label>
              <input inputmode="decimal" name="none" type="text" class="text"
                (input)="sanitizeNumberInput($event, 'gas')" (paste)="sanitizeNumberInput($event, 'gas')"
                [placeholder]="getTotalDefaultGas() || '0'" [(ngModel)]="customGasLimit">
            </div>
            <div class="flex-column">
              <label class="label">Fee</label>
              <input inputmode="decimal" name="none" type="text" class="text" [placeholder]="getTotalFee() || '0'"
                (paste)="sanitizeNumberInput($event)" (input)="sanitizeNumberInput($event)" [(ngModel)]="customFee">
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-column">
              <label class="label">Storage limit</label>
              <input inputmode="decimal" name="none" type="text" class="text"
                (input)="sanitizeNumberInput($event, 'storage')" (paste)="sanitizeNumberInput($event, 'storage')"
                [placeholder]="getTotalDefaultStorage() || '0'" [(ngModel)]="customStorageLimit">
            </div>
            <div class="flex-column">
              <label class="label">Max storage cost</label>
              <input inputmode="decimal" name="none" type="text" class="text read-only"
                [placeholder]="(getTotalBurn() || '0') + ' tez'" tabindex=-1 readonly>
            </div>
          </div>
        </form>
      </div>
      <div class="flex-column bottom">
        <span [style.visibility]="!pwdInvalid ? 'hidden' : ''" [style.margin]="!pwdInvalid ? '0' : ''"
          class="label danger">{{ this.pwdInvalid }}</span>
        <ng-container *ngIf="walletService.isPwdWallet()">
          <span class="label">ENTER YOUR
            PASSWORD TO CONFIRM:</span>
          <input name="none" [(ngModel)]="password" autocomplete="current-password" placeholder="Password"
            class="text password" type="password" (input)="this.pwdInvalid = ''" (change)="this.pwdInvalid = ''"
            (keydown.enter)='!messageService.spinnerOn ? inject() : null'>
        </ng-container>
        <button class="confirm" *ngIf="walletService.isLedgerWallet() && ledgerError; else elseBlock"
          (click)="ledgerRetry()">Sign with
          Ledger</button>
        <ng-template #elseBlock>
          <button class="confirm" (click)="!messageService.spinnerOn ? inject() : null"
            [disabled]="(walletService.isLedgerWallet() && (!sendResponse || !sendResponse.payload || !sendResponse.payload.signedOperation)) || walletService.isWatchWallet()">
            {{ walletService.isLedgerWallet() ? 'Broadcast' : 'Confirm'}}</button>
        </ng-template>
      </div>
    </div>
  </div>
</div>