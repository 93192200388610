<h3>Processing Request...</h3>
<br />
<h1 class="high-demand">High Demand for Items!</h1>
<br />
<p class="desc">Your request is being processed. Wait times vary.
</p>
<br />
<br />
<h1>Estimated Wait Time:</h1>
<h1 class="flex-row time">
  <span *ngIf="hours !== '00'"class="flex-column hours">
    <h1>{{ hours }}</h1>
    <h5>Hours</h5>
  </span>
  <span class="flex-column minutes">
    <h1>{{ minutes }}</h1>
    <h5>Minutes</h5>
  </span>
  <span class="flex-column seconds">
    <h1>{{ seconds }}</h1>
    <h5>Seconds</h5>
  </span>
</h1>
<br />
<br />
<br />
<h2 class="desc">At the end of the wait time, you will be asked to re-authenticate to access your account.</h2>