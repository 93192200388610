<div class="flex-column card">
  <a routerLink="/"><button class="flex-row back"><img src="../../../../../assets/img/left-arrow.svg">BACK</button></a>
  <div *ngIf="activePanel === 0" class="flex-column body">
    <h1>Import wallet</h1>
    <div class="flex-row import-options">
      <span [class.selected]="importOption===0" (click)="reset(); importOption = 0">Keystore File</span>
      <span [class.selected]="importOption===1" (click)="reset(); importOption = 1">Seed Words</span>
      <span [class.selected]="importOption===2" (click)="reset(); importOption = 2">Fundraiser</span>
    </div>
    <ng-container *ngIf="importOption === 0">
      <form autocomplete="off">
        <p class="description">
          Import your wallet from an encrypted keystore file (<span style="text-transform: lowercase;">.tez</span>)
        </p>
        <input #keyStore name="none" type="file" (change)="handleFileInput($event.target.files)"
          accept=".tez{{ firefox ? ', application/json' : '' }}" id="file" name="file">
        <div class="flex-row button input-file-wrapper">
          <label for="file" class="flex-row button input-file" [class.loaded]="walletJson"
            (click)="keyStore?.nativeElement?.click();">
            <ng-container *ngIf="!walletJson"><span class="b">Browse</span><img
                src="../../../../../assets/img/import-plus.png" /></ng-container>
            <ng-container *ngIf="walletJson">
              <div class="flex-row body"><img src="../../../../../assets/img/lock.png" />&nbsp;&nbsp;<span
                  class="flex-row filename">{{
                  fileName
                  }}</span>
              </div>
              <span class="b">Browse</span>
            </ng-container>
          </label>
        </div>
        <input *ngIf="walletJson" name="none" type="password" class="text" autocomplete="current-password"
          (keydown.enter)='!messageService.spinnerOn ? checkImportPwd() : null' id="pwd" [(ngModel)]="pwd"
          placeholder="Password">
        <button class="button next" [style.visibility]="!walletJson ? 'hidden' : ''"
          (click)='!messageService.spinnerOn ? checkImportPwd() : null'>Import</button>
      </form>
    </ng-container>
    <ng-container *ngIf="importOption > 0">
      <form autocomplete="off">
        <p class="description faucet" *ngIf="importOption === 2"><span>Please make sure to first <a
              routerLink="/activate">activate</a> your fundraiser wallet.</span></p>
        <span class="description label">RECOVERY (SEED) WORDS <span style="color: #98a0ab">12-24
            WORDS</span></span>
        <textarea name="none" class="text seed-word" [(ngModel)]="mnemonic" autocomplete="off" spellcheck="false"
          [placeholder]="'Seed Words (required)'" (keydown)="seedWordKeydown($event)"></textarea>
      </form>
      <ng-container *ngIf="importOption === 1">
        <app-ui-toggle-advanced style="width: 13rem;" [default]="false" (stateChange)="advancedForm = $event">
        </app-ui-toggle-advanced>
        <br /><br />
        <form autocomplete="off" *ngIf="advancedForm" class="flex-row-wrap import-option-1">
          <div class="flex-column">
            <span class="label"><b>If your seed words are protected by a passphrase, it needs to be
                entered here</b></span>
            <input name="none" class="text" type="password" name="passphrase" autocomplete="one-time-code"
              [(ngModel)]="passphrase" [placeholder]="'Passphrase (optional)'" readonly="true"
              (click)="clickPassword($event)" (blur)="blurPassword($event)">
          </div>
          <div class="flex-column">
            <span class="label"><b>Provide the address to verify your passphrase is correct</b></span>
            <input name="none" type="text" class="text" [(ngModel)]="pkh"
              [placeholder]='passphrase ? "tz1... (required)" : "tz1... (optional)"'>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="importOption === 2">
        <form autocomplete="off">
          <span class="label">The email used during the fundraiser</span>
          <input name="none" class="text" type="text" [(ngModel)]="email" [placeholder]="'Email (required)'">
          <span class="label">The password you chose during the fundraiser</span>
          <input name="none" class="text" type="password" name="tge-password" autocomplete="one-time-code"
            [(ngModel)]="password" [placeholder]="'Password (required)'" readonly="true" (click)="clickPassword($event)"
            (blur)="blurPassword($event)">
          <span class="label">Provide the public key hash to verify your email and password are correct</span>
          <input name="none" type="text" class="text" [(ngModel)]="pkh" [placeholder]="'tz1... (recomended)'">
        </form>
      </ng-container>
      <button class="button next" (click)="retrieve()">Import</button>
    </ng-container>
  </div>
  <div *ngIf="activePanel==1 && chooseWalletState === chooseWalletStateEnum.UserDoesNotNeedToChooseWallet"
    class="flex-column body">
    <form autocomplete="off">
      <h1>Set a password</h1>
      <p class="description bold blue">This password will be used to create an encrypted keystore file.</p>
      <p class="description">When performing operations that need to be signed with your
        secret key, this password will be required</p>
      <input name="none" type="password" placeholder="{{ 'NEWWALLETCOMPONENT.PASSWORD' | translate }}" class="text"
        autocomplete="new-password" [(ngModel)]="pwd1" (keyup)="calcStrength()">
      <small class="strength"> {{ 'NEWWALLETCOMPONENT.PASSWORDSTRENGTH' | translate }} — <b
          [style.color]="pwdStrength === 'Catastrophic!' ? 'darkred' : pwdStrength === 'Very weak!' ? 'red' : pwdStrength === 'Weak!' ? 'orange' : pwdStrength === 'Strong!' ? 'green' : 'red'">{{
          pwdStrength }}</b></small>
      <input name="none" type="password" placeholder="{{ 'NEWWALLETCOMPONENT.CONFIRMPASSWORD' | translate }}"
        class="text" autocomplete="new-password" [(ngModel)]="pwd2">
      <button class="button next" [style.visibility]="!pwd1 ? 'hidden' : ''"
        (click)="setPwdOrConfirmWalletType()">Next</button>
    </form>
  </div>
  <div
    *ngIf="importOption === 1 && activePanel === 1 && chooseWalletState !== chooseWalletStateEnum.UserDoesNotNeedToChooseWallet"
    class="body flex-column">
    <h1>Confirm Wallet Type</h1>
    <p class="description" *ngIf="chooseWalletState !== chooseWalletStateEnum.TzktErrorEncountered">
      You have used both the HD and Legacy wallets derived from the seed words. Please choose one.
    </p>
    <p class="description" *ngIf="chooseWalletState === chooseWalletStateEnum.TzktErrorEncountered">
      We are currently unable to query information about your wallet. Please choose a wallet type.
    </p>
    <div class="radios flex-row">
      <div class="flex-column">
        <div class="wallet-type-option">
          <input name="none" (change)="storableWalletType = storableWalletTypeEnum.HdWallet" type="radio"
            name="walletType" value="hd" checked>
          <span>HD wallet</span>
        </div>
        <div>Address: {{hdCandidate.pkh}}</div>
        <div>Tez Balance: {{hdCandidate.balance ?? '-'}}</div>
      </div>
      <div class="flex-column">
        <div class="wallet-type-option">
          <input name="none" (change)="storableWalletType = storableWalletTypeEnum.LegacyWallet" type="radio"
            name="walletType" value="legacy">
          <span>Legacy wallet</span>
        </div>
        <div>Address: {{legacyCandidate.pkh}}</div>
        <div>Tez balance: {{legacyCandidate.balance ?? '-'}}</div>
      </div>
    </div>
    <button class="button next" (click)="setPwdAfterConfirmingWalletType()">Confirm Wallet Type</button>
  </div>
  <div *ngIf="activePanel==2" class="flex-column body">
    <h1>Wallet created!</h1>
    <p class="description bold">Your wallet is now set up and ready to be used.</p>
    <p class="description">Download your encrypted keystore file and import it when you want to access your wallet.
    </p>
    <div class="whitebox final">
      <p class="description bold pkh">Your public account address:<br />{{ showPkh() }}</p>
    </div>
    <button class="button next" (click)="download()">Download</button>
    <button class="button next" [style.visibility]="!Downloaded ? 'hidden' : ''" (click)="done()">Open Wallet</button>
  </div>
</div>