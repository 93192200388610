<div *ngIf="isOpen" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-row top">
      <div class="flex-row button close" (click)="closeModal()"><img class="x"
          src="../../../../../assets/img/close-btn-x.png" />
      </div>
      <div class="flex-column text">
        <h1 class="title">{{ title }}</h1>
        <h2 class="secondary">Choose a provider</h2>
      </div>
    </div>
    <div class="flex-column buy">
      <div class="flex-row option" (click)="buyTez(BuyProviderType.Coinbase)">
        <img src="assets/img/coinbase.png" />
        <div class="text">
          <h3>Coinbase</h3>
          <h4>Transfer from Coinbase</h4>
        </div>
      </div>
      <div class="flex-row option" (click)="buyTez(BuyProviderType.Transak)">
        <img src="assets/img/transak.png" />
        <div class="text">
          <h3>Transak</h3>
          <h4>Bank transfers &amp; local payment methods in 120+ countries</h4>
        </div>
      </div>
      <div class="flex-row option" (click)="buyTez(BuyProviderType.MoonPay)">
        <img src="assets/img/moonpay.svg" />
        <div class="text">
          <h3>Moonpay</h3>
          <h4>Cards &amp; bank transfers</h4>
        </div>
      </div>
    </div>
  </div>
</div>