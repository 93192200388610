import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-button',
  templateUrl: './basic.component.html'
})
export class BasicButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
