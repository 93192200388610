<div *ngIf="signRequest && payload && !template" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-column top">
      <img class="flex-row button close" (click)="rejectSigning()" src="../../../../assets/img/add-minterpop.svg" />
      <h1 class="title">{{ signRequest?.title ? signRequest.title : 'Sign Expression' }}</h1>
    </div>
    <div class="flex-column sign-expr">
      <div class="seperator"></div>
      <p class="previewAttention">{{ description ? description : 'An application has requested you sign an
        expression.' }}</p>
        <div class="seperator"></div>
      <div class="flex-row more-info" [class]="showMore ? 'open' : ''" (click)="toggle()">{{ !showMore ? 'Show More' : 'Show Less' }}<img src="../../../../assets/img/dropdown-minterpop.svg"></div>
      <div *ngIf="showMore" class="info">
        <textarea rows="6" readonly>{{ payload }}</textarea>
      </div>
      <div class="flex-column bottom">
        <span *ngIf="this.pwdInvalid" class="danger margin">{{ this.pwdInvalid }}</span>
        <button class="purple confirm" (click)="sign()">SIGN</button>
        <div class="footer">
          <span>Powered by</span><img class="logo" src="../../../../assets/img/header-logo-grey.svg">
        </div>
      </div>
    </div>
  </div>
</div>
<app-sign-expr-template *ngIf="signRequest && payload && template" [req]="{payload: payload, template: template, activeAccount: activeAccount}" (isApproved)="handleTemplateApproval($event)"></app-sign-expr-template>