<div *ngIf="this.path !== '/embedded'" class="foot-container" [class.dark]="true">
  <div class="flex-row links">
    <div class="flex-row left low">
      <a href="./terms-of-use" target="_blank">Terms of Use</a>
      <a href="./privacy-policy" target="_blank">Privacy</a>
      <a class="desktop" target="_blank" href="mailto:contact@kukai.app">Contact</a>
    </div>
    <div class="flex-column since low">
      <div class="flex-row-wrap" id="follow">
        <span>follow kukai on</span>
        <a href="https://github.com/kukai-wallet/kukai" target="_blank"><img src="../../../assets/img/logo-github.svg"></a>
        <a (click)="promptTelegramDialog($event)" id="tg"><img src="../../../assets/img/logo-telegram.svg"></a>
        <a href="https://twitter.com/KukaiWallet/" target="_blank"><img src="../../../assets/img/logo-twitter.svg"></a>
        <a id="since">Since 2018 &trade;</a>
      </div>
    </div>
    <div class="flex-row low">
      <a class="mobile" target="_blank" href="mailto:contact@kukai.app">Contact</a>
        <a class="docs" href="https://docs.kukai.app" target="_blank">
          Help</a>
    </div>
  </div>
</div>