<div [style.display]="!isOpen ? 'none' : ''" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-row top">
      <div class="flex-row button close" (click)="close()"><img class="x"
          src="../../../../../assets/img/close-btn-x.png" />
      </div>
      <h1 class="title">{{ title }}</h1>
    </div>
    <div class="flex-column info">
      <div class="message">
        {{ message }}
      </div>
      <div *ngIf="action" class="flex-column bottom">
        <div class="seperator"></div>
        <button class="button confirm" (click)="proceed()">{{ action }}</button>
      </div>
    </div>
  </div>
</div>