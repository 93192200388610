<div *ngIf="!CONSTANTS.MAINNET && !embedded" class="network"
  [style.boxShadow]="document?.body.scrollTop !== 0 ? '0 2px 10px #3333' : ''">
  WARNING: TEST ONLY {{ CONSTANTS.NETWORK.toUpperCase() }}
</div>
<a *ngIf="promoteStake" (click)="stakeClick()" class="stake" href="https://stake.tezos.com" target="_blank">
  <img src="../assets/img/stake-icon.svg">
  <p><a>Stake your (XTZ)</a>{{' to receive' + (isMobile ? '\n' : ' ') + 'more rewards than delegation' }}</p>
  <img src="../assets/img/stake-icon.svg">
</a>
<app-header *ngIf="!embedded"></app-header>
<router-outlet></router-outlet>
<app-messages *ngIf="!embedded"></app-messages>
<app-spinner [embedded]="embedded"></app-spinner>
<app-agreement *ngIf="!embedded"></app-agreement>
<app-info></app-info>
<app-footer *ngIf="!embedded" [class.initial]="router.url !== '/'"></app-footer>