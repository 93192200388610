<div *ngIf="isOpen" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-row top">
      <div class="flex-row button close" (click)="closeModal()"><img class="x"
          src="../../../assets/img/close-btn-x.png" /></div>
      <h1 class="title">Receive Address</h1>
    </div>
    <div class="seperator"></div>
    <div class="flex-column receive">
      <label class="value">Your account QR code</label>
      <canvas id='canvas'></canvas>
      <span class="flex-row value">{{ activeAddress }}<img class="copy" src="../../../assets/img/copy-icon.svg"
          (click)="copy()" /></span>
    </div>
  </div>
</div>