<button *ngIf="!override" class="scan" (click)="openModal()">Scan QR code</button>
<img *ngIf="override" class="scan override" (click)="openModal()" src="assets/img/qr.svg" />
<div *ngIf="isOpen" class="flex-column kukai-modal">
  <form autocomplete="off">
    <div class="content">
      <div class="flex-row top">
        <div class="flex-row button close" (click)="closeModal()"><img class="x"
            src="../../../assets/img/close-btn-x.png" /></div>
        <h1 class="title">QR-Scanner</h1>
      </div>
      <div class="seperator"></div>
      <div class="flex-column qr-scanner">
        <div class="video-container">
          <video #videoPlayer>
            Browser not supported
          </video>
          <p class="no-cam" *ngIf="!qrScanner">No camera detected</p>
          <p class="no-cam" *ngIf="!!errorMessage">{{ errorMessage }}</p>
          <p class="no-cam" *ngIf="!!errorMessage">Please check site permissions</p>
        </div>
        <input *ngIf="!override" name="none" type="text" [(ngModel)]="manualInput" class="text" (paste)="handlePaste($event)"
          placeholder="...or paste pairing string here">
      </div>
    </div>
  </form>
</div>