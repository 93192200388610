import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logged-in',
  templateUrl: './logged-in.component.html'
})
export class LoggedInComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
