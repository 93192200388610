<div *ngIf="permissionRequest" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-row top">
      <div class="flex-row button close" (click)="rejectPermissions()"><img class="x"
          src="../../../assets/img/close-btn-x.png" /></div>
      <h1 class="title">Connect</h1>
    </div>
    <div class="flex-column permission-request">
      <span class="transport">{{ permissionRequest.version ? 'Beacon' : 'Wallet Connect'}}</span>
      <ng-container *ngIf="cachedIcon(permissionRequest); let cachedIcon;">
        <app-asset class="app-icon" [assets]="{ displayAsset: cachedIcon }"></app-asset>
      </ng-container>
      <span class="dapp-name">{{ permissionRequest.appMetadata.name }}</span>
      <span class="question">Do you want to connect to<br>this app?</span>
      <h6>Apply To Account:</h6>
      <app-ui-dropdown-permission-request [options]="accounts" [current]="preSelectedAccount"
        (dropdownResponse)="selectedAccount = $event" style="width: 100%;align-self: center;">
      </app-ui-dropdown-permission-request>
      <div class="flex-column bottom">
        <div class="seperator"></div>
        <button class="blue confirm" (click)="grantPermissions()">Connect</button>
      </div>
    </div>
  </div>
</div>